import {AppBar, Dialog, Divider, Grid, IconButton, List, ListItem, Toolbar, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import {useEffect, useState} from "react";
import moment from "moment";

function PickOrderDialog({open, handleClose, setConfirmOpen, products, setWithExistingId}) {

    const [orders, setOrders] = useState();

    useEffect(() => {
        if (open){
            fetch(`${process.env.REACT_APP_URL}/api/tires-soft/orders`, {
                method: "GET",
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((result) => result.json())
                .then((result) => {
                    setOrders(result)
                })
        }
    }, [open])

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'lg'} fullWidth>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Grid container spacing={1} style={{margin: 10}}>
                <Grid item xs={12}>
                    <List>
                        {
                            orders && orders.map((order) => {
                                return <ListItem onClick={() => {
                                    setConfirmOpen(products)
                                    setWithExistingId(order.id)
                                    handleClose()
                                }}>
                                    <Grid container spacing={1}>
                                       <Grid item xs={2}>
                                           {order.schedule ? <Typography>
                                                {moment(order.schedule.start).format('HH:mm')} - {moment(order.schedule.finish).format('HH:mm')}
                                            </Typography> : null}
                                        </Grid>
                                        <Grid item xs={1.5}>
                                            {order.car ? order.car.licence_plate : null}
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography>{order.description}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            })
                        }
                    </List>
                </Grid>
            </Grid>

        </Dialog>
    )
}
export default PickOrderDialog;