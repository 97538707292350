import * as React from "react";
import {useEffect, useState} from "react";
import {Dialog, Grid, Paper, Typography} from "@mui/material";
import {ALL_SEASONS_TIRES, CARD_HEIGHT, SUMMER_TIRES, WINTER_TIRES} from "../../../Constants";
import NewTiresSizeComponent from "./NewTiresSizeComponent";
import {localizeType} from "../../../LocalizationFunctions";
import ProductSearchComponent from "../props/ProductSearchComponent";
import NewProductDialog from "../../../dialogs/NewProductDialog";
import MessageDialog from "../../helper/MessageDialog";

function NewTiresSelectionComponent({selectedTiresType, setSelectedTiresType,
                                        numTires, setNumTires,
                                        newTires1, setNewTires1,
                                        newTires2, setNewTires2,
                                        newTires3, setNewTires3,
                                        newTires4, setNewTires4,
                                        newTires5, setNewTires5,
                                        newTires6, setNewTires6,

                                        newTires1ProductData, setNewTires1ProductData,
                                        newTires2ProductData, setNewTires2ProductData,
                                        newTires3ProductData, setNewTires3ProductData,
                                        newTires4ProductData, setNewTires4ProductData,
                                        newTires5ProductData, setNewTires5ProductData,
                                        newTires6ProductData, setNewTires6ProductData,

                                        newTires1Brand, setNewTires1Brand,
                                        newTires2Brand, setNewTires2Brand,
                                        newTires3Brand, setNewTires3Brand,
                                        newTires4Brand, setNewTires4Brand,
                                        newTires5Brand, setNewTires5Brand,
                                        newTires6Brand, setNewTires6Brand,


                                        newTires1Num, setNewTires1Num,
                                        newTires2Num, setNewTires2Num,
                                        newTires3Num, setNewTires3Num,
                                        newTires4Num, setNewTires4Num,
                                        newTires5Num, setNewTires5Num,
                                        newTires6Num, setNewTires6Num,


                                        newTires1Type, setNewTires1Type,
                                        newTires2Type, setNewTires2Type,
                                        newTires3Type, setNewTires3Type,
                                        newTires4Type, setNewTires4Type,
                                        newTires5Type, setNewTires5Type,
                                        newTires6Type, setNewTires6Type,



                                        newTireWeightIndex1, setNewTireWeightIndex1,
                                        newTireWeightIndex2, setNewTireWeightIndex2,
                                        newTireWeightIndex3, setNewTireWeightIndex3,
                                        newTireWeightIndex4, setNewTireWeightIndex4,
                                        newTireWeightIndex5, setNewTireWeightIndex5,
                                        newTireWeightIndex6, setNewTireWeightIndex6,


                                        newTireSpeedIndex1, setNewTireSpeedIndex1,
                                        newTireSpeedIndex2, setNewTireSpeedIndex2,
                                        newTireSpeedIndex3, setNewTireSpeedIndex3,
                                        newTireSpeedIndex4, setNewTireSpeedIndex4,
                                        newTireSpeedIndex5, setNewTireSpeedIndex5,
                                        newTireSpeedIndex6, setNewTireSpeedIndex6
}) {

    const [dialogOpen, setDialogOpen] = useState(null);

    const [searchTerm, setSearchTerm] = useState("");
    const [searchTermToDisplay, setSearchTermToDisplay] = useState("");
    const [createTiresDialog, setCreateTiresDialog] = useState("");
    const [showProductsSearch, setShowProductsSearch] = useState(false);
    const [tireToWorkWith, setTireToWorkWith] = useState(1);
    const [message, setMessage] = useState();

    function maskString(input) {
        if (input && input.length >= 3) {
            setShowProductsSearch(true)

            const firstPart = input.slice(0, 3);
            const remainingPart = input.slice(3, 5);
            const lastPart = input.slice(5);
            return `${firstPart}/${remainingPart}${lastPart ? 'R' : ''}${lastPart}`;
        } else {
            setShowProductsSearch(false)

            return input;
        }
    }

    function humanizeType(type) {
        switch (type) {
            case 'SUMMER_TIRES':
                return 'л';
            case 'WINTER_TIRES':
                return 'зим';
            case 'ALL_SEASONS_TIRES':
                return 'всесез';
            default:
                return '';
        }
    }

    function humanizeTypeToDisplay(type) {
        switch (type) {
            case 'SUMMER_TIRES':
                return 'лятна';
            case 'WINTER_TIRES':
                return 'зимна';
            case 'ALL_SEASONS_TIRES':
                return 'всесезонна';
            default:
                return '';
        }
    }

    useEffect(() => {
        console.log('newTires1Brand ', newTires1Brand)
        console.log('tireToWorkWith ', tireToWorkWith)
        switch (tireToWorkWith) {
            case 1:
                setSearchTerm(`${maskString(newTires1)??""} ${newTires1Brand??""} ${humanizeType(newTires1Type)??""} ${newTireWeightIndex1??""} ${newTireSpeedIndex1??""}`)
                setSearchTermToDisplay(`${maskString(newTires1)??""} ${newTires1Brand??""} ${humanizeTypeToDisplay(newTires1Type)??""} ${newTireWeightIndex1??""} ${newTireSpeedIndex1??""}`)
                break;
            case 2:
                setSearchTerm(`${maskString(newTires2)??""} ${newTires2Brand??""} ${humanizeType(newTires2Type)??""} ${newTireWeightIndex2??""} ${newTireSpeedIndex2??""}`)
                setSearchTermToDisplay(`${maskString(newTires2)??""} ${newTires2Brand??""} ${humanizeTypeToDisplay(newTires2Type)??""} ${newTireWeightIndex2??""} ${newTireSpeedIndex2??""}`)
                break;
            case 3:
                setSearchTerm(`${maskString(newTires3)??""} ${newTires3Brand??""} ${humanizeType(newTires3Type)??""} ${newTireWeightIndex3??""} ${newTireSpeedIndex3??""}`)
                setSearchTermToDisplay(`${maskString(newTires3)??""} ${newTires3Brand??""} ${humanizeTypeToDisplay(newTires3Type)??""} ${newTireWeightIndex3??""} ${newTireSpeedIndex3??""}`)
                break;
            case 4:
                setSearchTerm(`${maskString(newTires4)??""} ${newTires4Brand??""} ${humanizeType(newTires4Type)??""} ${newTireWeightIndex4??""} ${newTireSpeedIndex4??""}`)
                setSearchTermToDisplay(`${maskString(newTires4)??""} ${newTires4Brand??""} ${humanizeTypeToDisplay(newTires4Type)??""} ${newTireWeightIndex4??""} ${newTireSpeedIndex4??""}`)
                break;
            case 5:
                setSearchTerm(`${maskString(newTires5)??""} ${newTires5Brand??""} ${humanizeType(newTires5Type)??""} ${newTireWeightIndex5??""} ${newTireSpeedIndex5??""}`)
                setSearchTermToDisplay(`${maskString(newTires5)??""} ${newTires5Brand??""} ${humanizeTypeToDisplay(newTires5Type)??""} ${newTireWeightIndex5??""} ${newTireSpeedIndex5??""}`)
                break;
            case 6:
                setSearchTerm(`${maskString(newTires6)??""} ${newTires6Brand??""} ${humanizeType(newTires6Type)??""} ${newTireWeightIndex6??""} ${newTireSpeedIndex6??""}`)
                setSearchTermToDisplay(`${maskString(newTires6)??""} ${newTires6Brand??""} ${humanizeTypeToDisplay(newTires6Type)??""} ${newTireWeightIndex6??""} ${newTireSpeedIndex6??""}`)
        }
    }, [
        selectedTiresType,

        newTires1, newTires2, newTires3, newTires4, newTires5, newTires6,

        newTires1Brand, newTires2Brand, newTires3Brand, newTires4Brand, newTires5Brand, newTires6Brand,

        newTires1Num, newTires2Num, newTires3Num, newTires4Num, newTires5Num, newTires6Num,

        newTires1Type, newTires2Type, newTires3Type, newTires4Type, newTires5Type, newTires6Type,

        newTireWeightIndex1, newTireWeightIndex2, newTireWeightIndex3, newTireWeightIndex4, newTireWeightIndex5, newTireWeightIndex6,

        newTireSpeedIndex1, newTireSpeedIndex2, newTireSpeedIndex3, newTireSpeedIndex4, newTireSpeedIndex5, newTireSpeedIndex6
    ])


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Paper elevation={6} style={{height: CARD_HEIGHT}} className={'paperCenterText'} onClick={() => {
                    setDialogOpen(true)
                }}>
                    <Typography>Нови гуми</Typography>
                </Paper>
            </Grid>

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth={'lg'} fullWidth>
                <Grid container spacing={2} style={{margin: 10}}>
                    {
                        [SUMMER_TIRES, WINTER_TIRES, ALL_SEASONS_TIRES].map((type) => {
                            return (
                                <Grid item xs={2}>
                                    <Paper elevation={6}
                                           style={{background: selectedTiresType === type ? 'green' : 'white'}}
                                           className={'paperCenterText'} onClick={() => {
                                               if (numTires === 0) {
                                                   setNumTires(1)
                                               }
                                               if (selectedTiresType === type) {
                                                   setSelectedTiresType(null)
                                               } else {
                                                   setSelectedTiresType(type)
                                               }
                                    }}>
                                        {localizeType(type)}
                                    </Paper>
                                </Grid>
                            )})

                    }

                    <Grid item xs={12}>
                        <NewTiresSizeComponent onSave={() => {
                        }}
                                               tiresToWorkWith={tireToWorkWith}
                                               setTireToWorkWith={setTireToWorkWith}


                                               numTires={numTires} setNumTires={setNumTires}
                                               newTires1={newTires1} setNewTires1={setNewTires1}
                                               newTires2={newTires2} setNewTires2={setNewTires2}
                                               newTires3={newTires3} setNewTires3={setNewTires3}
                                               newTires4={newTires4} setNewTires4={setNewTires4}
                                               newTires5={newTires5} setNewTires5={setNewTires5}
                                               newTires6={newTires6} setNewTires6={setNewTires6}


                                               newProductsDate={[newTires1ProductData, newTires2ProductData, newTires3ProductData,
                                                   newTires4ProductData, newTires5ProductData, newTires6ProductData]}
                                               setProductData={[setNewTires1ProductData, setNewTires2ProductData, setNewTires3ProductData,
                                                   setNewTires4ProductData, setNewTires5ProductData, setNewTires6ProductData]}


                                               newTires1Brand={newTires1Brand} setNewTires1Brand={setNewTires1Brand}
                                               newTires2Brand={newTires2Brand} setNewTires2Brand={setNewTires2Brand}
                                               newTires3Brand={newTires3Brand} setNewTires3Brand={setNewTires3Brand}
                                               newTires4Brand={newTires4Brand} setNewTires4Brand={setNewTires4Brand}
                                               newTires5Brand={newTires5Brand} setNewTires5Brand={setNewTires5Brand}
                                               newTires6Brand={newTires6Brand} setNewTires6Brand={setNewTires6Brand}


                                               newTires1Num={newTires1Num}     setNewTires1Num={setNewTires1Num}
                                               newTires2Num={newTires2Num}     setNewTires2Num={setNewTires2Num}
                                               newTires3Num={newTires3Num}     setNewTires3Num={setNewTires3Num}
                                               newTires4Num={newTires4Num}     setNewTires4Num={setNewTires4Num}
                                               newTires5Num={newTires5Num}     setNewTires5Num={setNewTires5Num}
                                               newTires6Num={newTires6Num}     setNewTires6Num={setNewTires6Num}


                                               newTires1Type={newTires1Type}   setNewTires1Type={setNewTires1Type}
                                               newTires2Type={newTires2Type}   setNewTires2Type={setNewTires2Type}
                                               newTires3Type={newTires3Type}   setNewTires3Type={setNewTires3Type}
                                               newTires4Type={newTires4Type}   setNewTires4Type={setNewTires4Type}
                                               newTires5Type={newTires5Type}   setNewTires5Type={setNewTires5Type}
                                               newTires6Type={newTires6Type}   setNewTires6Type={setNewTires6Type}



                                               newTireWeightIndex1={newTireWeightIndex1} setNewTireWeightIndex1={setNewTireWeightIndex1}
                                               newTireWeightIndex2={newTireWeightIndex2} setNewTireWeightIndex2={setNewTireWeightIndex2}
                                               newTireWeightIndex3={newTireWeightIndex3}  setNewTireWeightIndex3={setNewTireWeightIndex3}
                                               newTireWeightIndex4={newTireWeightIndex4}  setNewTireWeightIndex4={setNewTireWeightIndex4}
                                               newTireWeightIndex5={newTireWeightIndex5}  setNewTireWeightIndex5={setNewTireWeightIndex5}
                                               newTireWeightIndex6={newTireWeightIndex6}  setNewTireWeightIndex6={setNewTireWeightIndex6}



                                               newTireSpeedIndex1={newTireSpeedIndex1}  setNewTireSpeedIndex1={setNewTireSpeedIndex1}
                                               newTireSpeedIndex2={newTireSpeedIndex2}  setNewTireSpeedIndex2={setNewTireSpeedIndex2}
                                               newTireSpeedIndex3={newTireSpeedIndex3}  setNewTireSpeedIndex3={setNewTireSpeedIndex3}
                                               newTireSpeedIndex4={newTireSpeedIndex4}  setNewTireSpeedIndex4={setNewTireSpeedIndex4}
                                               newTireSpeedIndex5={newTireSpeedIndex5}  setNewTireSpeedIndex5={setNewTireSpeedIndex5}
                                               newTireSpeedIndex6={newTireSpeedIndex6}  setNewTireSpeedIndex6={setNewTireSpeedIndex6}



                                               type={selectedTiresType}/>

                    </Grid>

                    {showProductsSearch ? <Grid item xs={12}>
                        <ProductSearchComponent searchTerm={searchTerm} searchTermToDisplay={searchTermToDisplay} setEnableCreation={setCreateTiresDialog}
                                                handleClose={() => setShowProductsSearch(false)}
                                                setProductData={[setNewTires1ProductData, setNewTires2ProductData, setNewTires3ProductData, setNewTires4ProductData, setNewTires5ProductData, setNewTires6ProductData][tireToWorkWith - 1]}
                                                quantity={[newTires1Num, newTires2Num, newTires3Num, newTires4Num, newTires5Num, newTires6Num][tireToWorkWith - 1]}/>
                    </Grid> : null}

                </Grid>

            </Dialog>


            {createTiresDialog ? <NewProductDialog open={createTiresDialog} handleClose={() => {
                setCreateTiresDialog(null)
            }} productToCreate={searchTermToDisplay} onProductPick={(id) => {
                let quantity = [newTires1Num, newTires2Num, newTires3Num, newTires4Num, newTires5Num, newTires6Num][tireToWorkWith - 1];
                let setProductData = [setNewTires1ProductData, setNewTires2ProductData, setNewTires3ProductData, setNewTires4ProductData, setNewTires5ProductData, setNewTires6ProductData][tireToWorkWith - 1];

                if (Number(quantity)<=0) {
                    setMessage('Изберете брой гуми първо!')
                    return;
                }
                let item = {
                    id: id,
                    name: searchTermToDisplay ? searchTermToDisplay : 'n/a',
                    quantity: Number(quantity.reduce((partialSum, a) => partialSum + a, 0)),
                    price: 1000 // TODO: to be removed
                }
                setMessage(`Успешно добавихте към поръчката ${searchTermToDisplay}!`)

                setProductData(item)
                setSearchTerm(null)
                setSearchTermToDisplay(null)

            }}/> : null}

            <MessageDialog openAndText={message} handleClose={() => setMessage(null)}
                           positiveText={'Добре'} positiveAction={() => setMessage(null)}/>
        </Grid>
    )
}
export default NewTiresSelectionComponent;