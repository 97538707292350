import * as React from "react";
import {AppBar, Dialog, Grid, IconButton, TextField, Toolbar} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Keyboard09 from "../../../customViews/Keyboard09";
import {useState} from "react";

function DotDialog({open, handleClose, index, setIndex, onClick}) {
    const [showKeyboard1, setShowKeyboard1] = useState(true);

    return (
    <Dialog open={open} onClose={handleClose} maxWidth={'md'} fullWidth>
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar>
        </AppBar>

        <Grid container spacing={1} style={{padding: 10}}>
            <Grid item xs={6}>
                <TextField value={index}
                           onChange={showKeyboard1 ? () => {} : (e) => setIndex(e.target.value)}/>
            </Grid>

            <Grid item xs={6}>
                <Keyboard09 customOptions={[
                    "1", "2", "3", "4", "5", "6", "7", "8", "9", "0",
                    "Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P",
                    "A", "S", "D", "F", "G", "H", "J", "K", "L",
                    "Z", "X", "C", "V", "B", "N", "M",
                    ".", "/", "-", "+"
                ]} input={index} setInput={setIndex} onSave={() => {
                    handleClose()
                }}
                    showKeyboard={showKeyboard1} setShowKeyboard={setShowKeyboard1}/>
            </Grid>
        </Grid>

    </Dialog>
    )
}
export default DotDialog;