import {ButtonBase, Grid, Paper, Typography} from "@mui/material";
import * as React from "react";
import {BIG_CARD_HEIGHT, CARD_HEIGHT} from "../../../Constants";

function PropsWithOptionsComponent({name, optionNames, options, setOptions, optionsDialog, setOptionsDialogOpen, jsxDialog, shouldSum}) {
    return (
        <Grid container spacing={2}>
            {jsxDialog}

            <Grid item xs={12}>
                <ButtonBase style={{width: '100%'}}>
                    <Paper elevation={6} style={{width: '100%', minHeight: CARD_HEIGHT, maxHeight: BIG_CARD_HEIGHT, padding: 10}} className={'paperCenterText'}
                    onClick={() => {
                        setOptionsDialogOpen(true);
                    }}>
                        <Grid container>

                            <Grid item xs={12}>
                                <Typography>{name}</Typography>
                            </Grid>


                        { options && options.length > 0 ?
                            <Grid item xs={12}>
                                <Grid container justifyContent={'flex-start'}>
                                    {
                                        options && options.map((option, index) =>
                                            option && option.length > 0 ?
                                                <React.Fragment>
                                                    {optionNames[index] ? <Grid item xs={5}><Typography style={{textAlign: 'start'}}><b>{optionNames[index]}</b></Typography></Grid> : null }
                                                        {
                                                            shouldSum ?
                                                                option && option.length>0 ? <Grid item xs={7}><Typography>{option.reduce((partialSum, a) => partialSum + a, 0)}</Typography></Grid> : <Grid item xs={9}></Grid>
                                                                :
                                                            option && option.length>0 ? option.map((subOption) => <Grid item xs={7 / option.length}>
                                                                    <Typography variant={'h6'}>{subOption}</Typography>
                                                                </Grid>
                                                            ) : <Grid item xs={7}></Grid>
                                                        }
                                                </React.Fragment>
                                                : null

                                        )
                                    }
                                </Grid>

                            </Grid> : null
                        }
                        </Grid>

                    </Paper>
                </ButtonBase>
            </Grid>

        </Grid>
    )
}
export default PropsWithOptionsComponent;