import {
    AppBar, Box, Button, Chip,
    Dialog,
    Divider, FormControl,
    Grid,
    IconButton, InputLabel,
    List,
    ListItem, MenuItem, OutlinedInput, Select,
    Table,
    TableCell,
    TableRow,
    Toolbar
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import {CARD_HEIGHT} from "../../Constants";
import {useEffect, useState} from "react";
import ClientPickComponent from "../../components/controllers/clientPick/ClientPickComponent";
import MessageDialog from "../../components/helper/MessageDialog";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function AddProductsDialog({open, handleClose, products, withExistingId, hotelData,
                                              setEditOrder, resetAllProps, presetMechanics, editOrder,
                                              presetClientData, setPresetClientData}) {

    const [names, setNames] = React.useState([]);
    const [personName, setPersonName] = React.useState([]);

    const [vehicleId, setVehicleId] = React.useState(null);
    const [clientId, setClientId] = React.useState(null);
    const [messageText, setMessageText] = useState();

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    useEffect(() => {
        if (open && presetMechanics && presetMechanics.length>0) {
            setPersonName(presetMechanics)
        } else {
            setPersonName([])
        }
    }, [open, presetMechanics])

    useEffect(() => {
        if (open && presetClientData) {
            setClientId(presetClientData.clientId)
            setVehicleId(presetClientData.vehicleId)
        } else {
            setClientId(null)
            setVehicleId(null)
        }
    }, [open, presetClientData])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/api/tires-soft/mechanics?`+new URLSearchParams({
            warehouseId: 1
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(result => result.json())
        .then((result) => {
            setNames(result)
        })
    }, [open])


    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'md'} fullWidth>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Grid container spacing={1} style={{margin: 10}}>
                <Grid item xs={6}>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-chip-label">Механици</InputLabel>
                        <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={personName}
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Механици" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={(names?.find(e => e.id === value) ?? {}).name} />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {
                                console.log('names ', names)
                            }
                            {names && names.map((item) => (
                                <MenuItem
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    {!withExistingId ? <ClientPickComponent setVehicleId={setVehicleId} setClientId={setClientId}
                                                            artificialTextFromOutside={presetClientData}/> : null}
                </Grid>


                <Grid item xs={12}>
                    <Table>
                        <TableRow>
                            <TableCell>Име</TableCell>
                            <TableCell>Бр</TableCell>
                        </TableRow>
                        {
                            products && products.map((product) => {
                                return <TableRow>
                                        <TableCell>{product.name}</TableCell>
                                        <TableCell>{product.quantity}</TableCell>
                                    </TableRow>
                            })
                        }
                    </Table>
                </Grid>

                <Grid item xs={4}>
                    <Button style={{height: CARD_HEIGHT}} variant={'contained'} onClick={() => {
                        if ((!withExistingId && !clientId) || !personName || personName === []) {
                            setMessageText('Механиците и клиентът са задължителни реквизити!')
                            return;
                        }
                        if (withExistingId) {
                            fetch(`${process.env.REACT_APP_URL}/api/tires-soft/order/existing`, {
                                method: "POST",
                                mode: 'cors',
                                headers: {
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify({
                                    id: withExistingId,
                                    warehouse_id: 1,
                                    products: open,
                                    client_id: clientId || (editOrder ? editOrder.client_id : null),
                                    vehicle_id: vehicleId,
                                    mechanics: personName,
                                    ...hotelData
                                })
                            }).then((result) => {
                                setClientId(null)
                                setVehicleId(null)
                                resetAllProps()
                                handleClose()
                            })
                            setEditOrder(null)
                        } else {
                            if (!presetClientData) {
                                setEditOrder(null)
                            }

                            fetch(`${process.env.REACT_APP_URL}/api/tires-soft/order`, {
                                method: "POST",
                                mode: 'cors',
                                headers: {
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify({
                                    warehouse_id: 1,
                                    products: open,
                                    client_id: clientId,
                                    vehicle_id: vehicleId,
                                    mechanics: personName,
                                    ...hotelData
                                })
                            }).then((result) => result.json())
                            .then((result) => {
                                setClientId(null)
                                setVehicleId(null)
                                if (presetClientData && result.order) {
                                    setEditOrder(result.order)
                                    setPresetClientData(null)
                                }
                                resetAllProps()
                                handleClose()
                            })
                        }
                    }}>Потвърди</Button>
                </Grid>

            </Grid>

            <MessageDialog openAndText={messageText} handleClose={() => setMessageText(null)}
                           positiveText={'Добре'} positiveAction={() => setMessageText(null)}/>


        </Dialog>
    )
}