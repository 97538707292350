import * as React from "react";
import { IMaskInput } from 'react-imask';
import {Input} from "@mui/material";

function TiresSizeMask({value}) {
    return (
        <Input
            style={{width: 90}}
            value={value}
            disabled
            id="formatted-text-mask-input"
            inputComponent={TextMaskCustom}
            InputProps={{
                classes:{
                    disabled: {color: 'black', colorDisabled: 'black'}
                }
            }}
        />
    );
}


const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="###/##R##"
            definitions={{
                '#': /[0-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});
export default TiresSizeMask;