export const SUMMER_TIRES = 'SUMMER_TIRES';
export const WINTER_TIRES = 'WINTER_TIRES';
export const ALL_SEASONS_TIRES = 'ALL_SEASONS_TIRES';

export const CARD_HEIGHT = 60;
export const BIG_CARD_HEIGHT = 150;

export const ENGLISH_ALPHABET = ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P",
    "A", "S", "D", "F", "G", "H", "J", "K", "L",
    "Z", "X", "C", "V", "B", "N", "M"]

export const BULGARIAN_ALPHABET = ["Я", "В", "Е", "Р", "Т", "Ъ", "У", "И", "О", "П", "Ш", "Щ",
    "А", "С", "Д", "Ф", "Г", "Х", "Ж", "З", "Й", "К", "Л", "Ч", "Ц", "Ь", "Б", "Н", "М"]