import {AppBar, Dialog, FormControlLabel, Grid, IconButton, Paper, Switch, Toolbar, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import {useEffect, useState} from "react";
import EditOrderValuePickDialog from "./EditOrderValuePickDialog";
import {BULGARIAN_ALPHABET, ENGLISH_ALPHABET} from "../Constants";
import AddIcon from '@mui/icons-material/Add';
import moment from "moment";

export default function OrdersListDialog({open, handleClose, clientId, vehicleId, setEditOrder,
                                             setConfirmOpen, setPresetClientData,
                                             clientProp}) {

    const [items, setItems] = useState([]);
    const [englishOrBulgarian, setEnglishOrBulgarian] = useState(true);


    const [tiresOnlyEnabled, setTiresOnlyEnabled] = useState(true);

    const [descriptionPickOpen, setDescriptionPickOpen] = useState();

    useEffect(() => {
        if (open && clientId) {
            fetch(`${process.env.REACT_APP_URL}/api/orders/filters?`+new URLSearchParams({
                client_id: clientId,
                ...vehicleId ? {vehicle_id: vehicleId} : {},
                ...tiresOnlyEnabled ? {department_id: 1} : {}
            }), {
                method: "GET",
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(result => result.json())
                .then((result) => {
                    setItems(result)
                })
                .catch((reason) => {
                    console.log(reason)
                })
        }
    }, [open, clientId, vehicleId, tiresOnlyEnabled])

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'md'} fullWidth>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Grid container spacing={1} style={{margin: 10}}>
                <Grid item xs={12}>
                    <FormControlLabel control={<Switch
                        checked={tiresOnlyEnabled}
                        onChange={(event) => {
                            setTiresOnlyEnabled(event.target.checked);
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />} label="Само поръчки за гуми"/>
                </Grid>
                <Grid item xs={3}>
                    <Paper elevation={6} style={{padding: 5, cursor: 'pointer'}} onClick={() => {
                        setPresetClientData({
                            clientId: clientId,
                            vehicleId: vehicleId,
                            clientText: clientProp ? `${clientProp.name} ${clientProp.car}` : null
                        })
                        setConfirmOpen([])
                        handleClose()
                    }}>
                        <Grid container spacing={1} justifyContent={'center'}>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12}></Grid>

                            <Grid item>
                                <AddIcon fontSize={'large'}/>
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item>
                                <Typography style={{align: 'center'}}>Създай поръчка</Typography>
                            </Grid>

                            <Grid item xs={12}></Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12}></Grid>

                        </Grid>
                    </Paper>
                </Grid>
                {(items??[]).filter((order) => order).map((order) => {
                    return <Grid item xs={3}>
                        <Paper elevation={6} style={{padding: 5, cursor: 'pointer'}} onClick={() => {
                            setDescriptionPickOpen({
                                id: order.id,
                                value: order.description,
                                order: order
                            })
                            setEditOrder(order)
                        }}>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    №{order.id}
                                </Grid>
                                <Grid item xs={12}>
                                    {order.date ? moment(order.date).format("DD.MM.YYYY") : null}
                                </Grid>
                                <Grid item xs={12}>
                                    {order.clients ? order.clients.name : 'Няма име'}
                                </Grid>
                                <Grid item xs={12}>
                                    {order.clients ? order.clients.telephone : 'Няма телефон'}
                                </Grid>
                                <Grid item xs={12}>
                                    {order.car ? order.car.make : 'Няма марка'} / {order.car ? order.car.licence_plate : 'Няма рег. ном.'}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                })}


            </Grid>



            <EditOrderValuePickDialog open={descriptionPickOpen}
                                      order={descriptionPickOpen ? descriptionPickOpen.order : null}
                                      setEditOrder={setEditOrder}
                                      predefinedValue={descriptionPickOpen ? descriptionPickOpen.value : null} handleClose={(value) => {
                if (descriptionPickOpen && value && (typeof value === 'string')) {
                    fetch(`${process.env.REACT_APP_URL}/api/tires-soft/order`, {
                        method: "PUT",
                        mode: 'cors',
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            id: descriptionPickOpen.id,
                            description: value,
                        })
                    })
                        .then(result => result.json())
                }
                setDescriptionPickOpen(null)
                handleClose()
            }} allowedLetters={[...(englishOrBulgarian ? BULGARIAN_ALPHABET : ENGLISH_ALPHABET),
                ".", "/", "-", "+", " "]}
                                      onLanguageToggle={() => {
                                          setEnglishOrBulgarian(!englishOrBulgarian)
                                      }}/>

        </Dialog>
    )
}