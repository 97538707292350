import {AppBar, Button, Dialog, Grid, IconButton, TextField, Toolbar, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import {useState} from "react";
import Keyboard09 from "../customViews/Keyboard09";

export default function NewProductDialog({open, handleClose, productToCreate, onProductPick}) {
    const [price, setPrice] = useState();
    const [showKeyboard1, setShowKeyboard1] = useState(false);

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Grid container spacing={1} style={{margin: 10}}>
                <Grid item xs={8}>
                    <Typography>Ще се създаде: <b>{productToCreate}</b></Typography>
                </Grid>
                <Grid item xs={5}>
                    <TextField label={'Цена'} value={price} InputLabelProps={{shrink: price}}
                               onChange={showKeyboard1 ? () => {} : (e) => setPrice(e.target.value)}/>
                </Grid>
                <Grid item xs={12}>
                    <Keyboard09 input={price} setInput={setPrice} onSave={() => {
                        fetch(`${process.env.REACT_APP_URL}/api/products/create`, {
                            method: "POST",
                            mode: 'cors',
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                name: productToCreate,
                                price: price
                            })
                        })
                            .then(result => result.json())
                            .then((result) => {
                                if (result.id) {
                                    onProductPick(result.id)
                                }
                            })
                            .catch(() => {
                                handleClose()
                            })

                        handleClose()
                    }} showKeyboard={showKeyboard1} setShowKeyboard={setShowKeyboard1}/>
                </Grid>
            </Grid>

        </Dialog>
    )
}