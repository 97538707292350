import * as React from "react";
import {AppBar, Dialog, Grid, IconButton, TextField, Toolbar} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Keyboard09 from "../../../customViews/Keyboard09";
import {BULGARIAN_ALPHABET, ENGLISH_ALPHABET} from "../../../Constants";
import {useState} from "react";

function AdditinalNoteDialog({open, handleClose, index, setIndex, onClick}) {
    const [englishOrBulgarian, setEnglishOrBulgarian] = useState(true);
    const [showKeyboard, setShowKeyboard] = useState(false);

    return (
    <Dialog open={open} onClose={handleClose} maxWidth={'md'} fullWidth>
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar>
        </AppBar>

        <Grid container spacing={1} style={{padding: 10}}>
            <Grid item xs={6}>
                <TextField value={index}
                           onChange={showKeyboard ? () => {} : (e) => setIndex(e.target.value)}/>
            </Grid>

            <Grid item xs={6}>
                <Keyboard09 customOptions={[
                    "1", "2", "3", "4", "5", "6", "7", "8", "9", "0",
                    ...(englishOrBulgarian ? BULGARIAN_ALPHABET : ENGLISH_ALPHABET),
                    ".", "!", "/", "-", "+", " "
                ]} input={index} setInput={setIndex} onSave={() => {
                    handleClose()
                }} onLanguageToggle={() => {
                    setEnglishOrBulgarian(!englishOrBulgarian)
                }} showKeyboard={showKeyboard} setShowKeyboard={setShowKeyboard}/>
            </Grid>
        </Grid>

    </Dialog>
    )
}
export default AdditinalNoteDialog;