import {AppBar, Button, Dialog, Grid, IconButton, Toolbar} from "@mui/material";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import HotelPickComponentOptions from "./HotelPickComponentOptions";

function HotelPickComponent({ open, handleClose, optionNames,

                                numHotels, setNumHotels,

                                options, setOptions, customOptions,
                                tiresBrand, setTiresBrand,
                                tiresSize, setTiresSize,
                                speedIndex, setSpeedIndex,
                                weightIndex, setWeightIndex,
                                dot, setDot,
                                hotelNote, setHotelNote,
                                onClick,


                                options2, setOptions2, customOptions2,
                                tiresBrand2, setTiresBrand2,
                                tiresSize2, setTiresSize2,
                                speedIndex2, setSpeedIndex2,
                                weightIndex2, setWeightIndex2,
                                dot2, setDot2,
                                hotelNote2, setHotelNote2,
                                onClick2,


                                options3, setOptions3, customOptions3,
                                tiresBrand3, setTiresBrand3,
                                tiresSize3, setTiresSize3,
                                speedIndex3, setSpeedIndex3,
                                weightIndex3, setWeightIndex3,
                                dot3, setDot3,
                                hotelNote3, setHotelNote3,
                                onClick3


}) {

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'md'} fullWidth>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Grid container spacing={2} style={{padding: 10}}>

                <Grid item xs={10.5}>
                    <HotelPickComponentOptions
                        optionNames={optionNames}

                        options={options} setOptions={setOptions} customOptions={customOptions}
                        tiresBrand={tiresBrand} setTiresBrand={setTiresBrand}
                        tiresSize={tiresSize} setTiresSize={setTiresSize}
                        speedIndex={speedIndex} setSpeedIndex={setSpeedIndex}
                        weightIndex={weightIndex} setWeightIndex={setWeightIndex}
                        dot={dot} setDot={setDot}
                        hotelNote={hotelNote} setHotelNote={setHotelNote}

                        onClick={onClick}/>
                </Grid>
                {numHotels === 1 ? <Grid item xs={0.5}><Button onClick={() => {
                    setNumHotels(2)
                }}>Добави ред</Button></Grid> : null}

                {numHotels >= 2 ? <><Grid item xs={10.5}>
                    <HotelPickComponentOptions
                        optionNames={optionNames}

                        options={options2} setOptions={setOptions2} customOptions={customOptions2}
                        tiresBrand={tiresBrand2} setTiresBrand={setTiresBrand2}
                        tiresSize={tiresSize2} setTiresSize={setTiresSize2}
                        speedIndex={speedIndex2} setSpeedIndex={setSpeedIndex2}
                        weightIndex={weightIndex2} setWeightIndex={setWeightIndex2}
                        dot={dot2} setDot={setDot2}
                        hotelNote={hotelNote2} setHotelNote={setHotelNote2}

                        onClick={onClick2}/>
                </Grid>
                {numHotels === 2 ? <Grid item xs={1.2}>
                        <Grid container>

                                <Grid item xs={12}>
                                    <Button onClick={() => {
                                        setNumHotels(3)
                                    }}>Добави ред</Button>
                                </Grid>
                            <Grid item xs={12}>
                                <Button onClick={() => {
                                    setNumHotels(1)
                                }}>Изтрий ред</Button>
                            </Grid>
                        </Grid>
                    </Grid> : null}

                </>: null}

                {numHotels >= 3 ? <><Grid item xs={10.5}>
                    <HotelPickComponentOptions
                        optionNames={optionNames}

                        options={options3} setOptions={setOptions3} customOptions={customOptions3}
                        tiresBrand={tiresBrand3} setTiresBrand={setTiresBrand3}
                        tiresSize={tiresSize3} setTiresSize={setTiresSize3}
                        speedIndex={speedIndex3} setSpeedIndex={setSpeedIndex3}
                        weightIndex={weightIndex3} setWeightIndex={setWeightIndex3}
                        dot={dot3} setDot={setDot3}
                        hotelNote={hotelNote3} setHotelNote={setHotelNote3}

                        onClick={onClick3}/>
                    </Grid>
                    <Grid item xs={0.5}>
                        <Button onClick={() => {
                            setNumHotels(2)
                        }}>Изтрий ред</Button>
                    </Grid>
                    </> : null}

            </Grid>


        </Dialog>
    )
}
export default HotelPickComponent;