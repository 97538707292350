import * as React from "react";
import {AppBar, ButtonBase, Dialog, Grid, IconButton, Paper, Toolbar, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {BIG_CARD_HEIGHT, CARD_HEIGHT} from "../../../Constants";

function TiresBrandsDialog({open, handleClose, brand, setBrand, onClick}) {

    const BRANDS = [
        'ATLAS', 'AUSTONE', 'AVON', 'BARUM',
        'BF GOODRICH', 'BFGOODRICH', 'BRIDGESTONE',
        'CONTINENTAL', 'COOPER', 'COOPER TIRES', 'CORDIANT',
        'DAYTON', 'DEBICA', 'DIPLOMAT', 'DUNLOP',
        'FALKEN', 'FEDERAL', 'FIRESTONE', 'FORTUNA', 'FULDA',
        'GENERAL', 'GISLAVED', 'GOLDLINE', 'GOODRIDE', 'GOODYEAR', 'GRIPMAX',
        'HANKOOK', 'HIFLY',
        'IMPERIAL',
        'KAMA', 'KENDA', 'KINGSTAR', 'KLEBER', 'KORMORAN', 'KUMHO',
        'LAUFENN', 'LINGLONG', 'LINGLONG-TYRES',
        'MARSHAL', 'MATADOR', 'MAXXIS', 'MICHELIN', 'MINERVA', 'MIRAGE',
        'NANKANG', 'NEXEN', 'NOKIAN', 'NOVEX',
        'ORIUM',
        'PETLAS', 'PIRELLI', 'POINT S', 'POWERTRAC',
        'RADAR', 'RIKEN', 'ROADHOG', 'ROADMARCH', 'ROADSTONE', 'ROADX', 'ROTALLA', 'ROYALBLACK',
        'SAILUN', 'SAVA', 'SEMPERIT', 'SONAR', 'SPORTIVA', 'STAR PERFORMER', 'SUMITOMO',
        'TAURUS', 'TIGAR', 'TOYO', 'TRACMAX', 'TRIANGLE', 'TRISTAR', 'TYFOON',
        'UNIGLORY', 'UNIGRIP', 'UNIROYAL',
        'VIKING', 'VOYAGER', 'VREDESTEIN',
        'WANLI', 'WARRIOR', 'WESTLAKE', 'WINDFORCE',
        'YOKOHAMA'
    ];

    return (
    <Dialog open={open} onClose={handleClose} maxWidth={'md'} fullWidth>
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <Grid container justifyContent={'space-between'}>
                    <Grid item>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>


                <Grid item>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                            onClick(null);
                            handleClose();
                        }}
                        aria-label="close"
                    >
                        Изчисти марка
                    </IconButton>
                </Grid>

            </Toolbar>
        </AppBar>

        <Grid container spacing={1} style={{padding: 10}}>
            {BRANDS.map((brandList) => {
                return <Grid item xs={3}>
                    <ButtonBase style={{width: '100%'}}>
                        <Paper style={{width: '100%', minHeight: CARD_HEIGHT, maxHeight: BIG_CARD_HEIGHT, padding: 10, background: brand === brandList ? 'green' : null}}
                                      className={'paperCenterText'}
                                      onClick={() => {
                                          //setBrand(brandList);
                                          onClick(brandList);
                                          handleClose()
                                      }}>

                            <Typography variant={'h6'}>{brandList}</Typography>
                        </Paper>
                    </ButtonBase>
                </Grid>

            })}
        </Grid>

    </Dialog>
    )
}
export default TiresBrandsDialog;