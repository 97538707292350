import {ButtonBase, Grid, Paper, Typography} from "@mui/material";
import * as React from "react";
import {CARD_HEIGHT} from "../../../Constants";

function PropsComponent({name, options, setOptions}) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Paper elevation={6} style={{height: CARD_HEIGHT}} className={'paperCenterText'}>
                    <Typography>{name}</Typography>
                </Paper>
            </Grid>
            {
                [1, 2, 4].map((num) => {
                    return (
                        <Grid item xs={2}>
                            <ButtonBase style={{width: '100%'}}>
                                <Paper elevation={6}
                                       className={'paperCenterText'}
                                       onClick={() => {
                                           if (options) {
                                               let newOptions = options;
                                               if (newOptions.includes(num)) {
                                                   newOptions = newOptions.filter(function(item) {
                                                       return item !== num
                                                   })
                                               } else {
                                                   newOptions.push(num)
                                               }
                                               console.log('setting', newOptions)
                                               setOptions([...newOptions])
                                           }
                                       }}
                                       style={{width: '100%', height: CARD_HEIGHT, background: options && options.includes(num) ? 'green' : 'white', cursor: 'pointer'}}>
                                    <Typography variant={'h6'}>{num}</Typography>
                                </Paper>
                            </ButtonBase>
                        </Grid>
                    )})

            }
        </Grid>
    )
}
export default PropsComponent;