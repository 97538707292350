import {Button, Dialog, Grid, Typography} from "@mui/material";
import * as React from "react";

export function ChangeOrderStatusDialog({open, handleClose}) {

    return (
        open ? <Dialog open={open} onClose={handleClose}>
            <Grid container spacing={1} padding={2}>
                <Grid item xs={12}>
                    <Typography>Да маркирам ли автомобила като {open.order_status === 0 ? 'ПРИЕТ' : 'НЕПРИЕТ'}?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button variant={'contained'} onClick={() => {
                        fetch(`${process.env.REACT_APP_URL}/api/kiosk/orders/status`, {
                            method: "POST",
                            mode: 'cors',
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                order_status: open.order_status === 0 ? 1 : 0,
                                order_id: open.id,
                            })
                        })
                            .then(result => result.json())
                            .then((result) => {
                                handleClose()
                            })
                    }}>Маркирай като {open.order_status === 0 ? 'ПРИЕТ' : 'НЕПРИЕТ'}</Button>
                </Grid>

            </Grid>

        </Dialog> : null
    )
}