import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import {Button, DialogActions} from "@mui/material";
import ReportIcon from '@mui/icons-material/Report';
import Grid from "@mui/material/Grid";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function MessageDialog({openAndText, handleClose,
                           positiveText, positiveAction,
                           negativeText, negativeAction,
                           dangerMode}) {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <Dialog
            maxWidth={'sm'}
            open={openAndText}
            onClose={handleClose}
            TransitionComponent={Transition}
        >

            <AppBar sx={{ position: 'relative', background: dangerMode ? 'red' : null }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>


                    <Typography sx={{ color: dangerMode ? 'white' : null, ml: 2, flex: 1 }} variant="h6" component="div">
                        {dangerMode ? '!Внимание!' : 'Съобщение'}
                    </Typography>

                </Toolbar>
            </AppBar>

            <div style={{margin: 20}}>
                <Typography variant={'h5'}>{openAndText}</Typography>
            </div>

            {((positiveText && positiveAction) || (negativeText && negativeAction)) ? <DialogActions>
                {(negativeText && negativeAction) ? <Button size={'large'} onClick={negativeAction}>{negativeText}</Button> : null}
                <Button size={'large'} onClick={positiveAction} autoFocus>
                    {positiveText}
                </Button>
            </DialogActions> : null}

        </Dialog>
    );
}
export default MessageDialog;