import {
    AppBar, ButtonBase,
    Dialog,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    Paper,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import Keyboard09 from "../../../customViews/Keyboard09";
import {useEffect, useState} from "react";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import {BIG_CARD_HEIGHT} from "../../../Constants";

function CountNumComponent({name, open, setOpen, handleClose, count, setCount, artNom, setArtNom, setProductData}) {

    const [tempCount, setTempCount] = useState();
    const [tempArtNom, setTempArtNom] = useState();

    const [step, setStep] = useState(0);


    const [productsSuggestions, setProductsSuggestions] = useState([]);


    const [showKeyboard1, setShowKeyboard1] = useState(true);
    const [showKeyboard2, setShowKeyboard2] = useState(true);


    useEffect(() => {
        if (tempArtNom && tempArtNom.length > 2) {
            fetch(`${process.env.REACT_APP_URL}/api/products/search?`+new URLSearchParams({
                input: tempArtNom,
                warehouseId: 1
            }), {
                method: "GET",
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((result) => result.json())
                .then((result) => {
                    let items = result;
                    if (items) {
                        items.length = Math.min(6, items.length);
                        setProductsSuggestions([...items])
                    }
                })
        } else {
            setProductsSuggestions([])
        }
    }, [tempArtNom])

    useEffect(() => {
        setStep(0)
        setTempCount(count);
        setArtNom(artNom);
    }, [open])

    return (
        <React.Fragment>

            <Paper elevation={6}
                   className={'paperCenterText'}
                   onClick={() => {
                       setOpen(true)
                   }}
                   style={{height: BIG_CARD_HEIGHT, cursor: 'pointer'}}>

                <Grid container justifyContent={'center'}>
                    <Grid item>
                        {name}
                    </Grid>
                    {count ? <Grid item xs={12}>
                        <Typography><b>Брой:</b> {count}</Typography>
                    </Grid> : null}
                    {artNom ? <Grid item xs={12}>
                        <Typography><b>Арт. ном:</b> {artNom}</Typography>
                    </Grid> : null}
                </Grid>
            </Paper>

            <Dialog open={open} onClose={handleClose} maxWidth={'lg'} fullWidth>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>


                {step === 0 ? <Grid container style={{margin: 10}}>

                    <Grid item xs={6}>
                        <TextField label={'Брой'} value={tempCount} InputLabelProps={{shrink: tempCount}}
                            onChange={showKeyboard1 ? () => {} : (e) => setTempCount(e.target.value)}/>
                    </Grid>

                    <Grid item xs={12}>
                        <Keyboard09 input={tempCount} setInput={setTempCount} onSave={() => {
                            setCount(tempCount)
                            setTempArtNom("")
                            setStep(1)
                        }} showKeyboard={showKeyboard1} setShowKeyboard={setShowKeyboard1}/>
                    </Grid>


                </Grid>
                    :


                 <Grid container style={{margin: 10}} spacing={1}>

                     <Grid item xs={9}>
                         <Grid item xs={6}>
                             <TextField label={'Арт. номер'} value={tempArtNom} InputLabelProps={{shrink: tempArtNom}}
                                        onChange={showKeyboard2 ? () => {} : (e) => setTempArtNom(e.target.value)}/>
                         </Grid>

                         <Grid item xs={12}>
                             <Keyboard09
                                 customInputOptions={['A', 'C', 'E', 'T']}
                                 input={tempArtNom} setInput={setTempArtNom} onSave={() => {
                                 setArtNom(tempArtNom)
                                 handleClose()
                             }} showKeyboard={showKeyboard2} setShowKeyboard={setShowKeyboard2}/>
                         </Grid>
                     </Grid>
                     <Grid item xs={3}>
                             <List>
                                 {
                                     productsSuggestions && productsSuggestions
                                         .map((product) => {
                                             return <ListItem style={{minHeight: 65}}>
                                                 <ButtonBase fullWidth style={{width: '100%', minHeight: 60}}
                                                        onClick={() => {
                                                            setProductData({
                                                                id: product.product_id,
                                                                name: product ? product.name + ' ' + product.code : 'n/a',
                                                                quantity: Number(tempCount)
                                                            })
                                                            setArtNom(tempArtNom)
                                                            handleClose()
                                                        }}>
                                                     {product ? product.name + ' ' + product.code : 'n/a'}
                                                 </ButtonBase>
                                                 <Divider/>
                                             </ListItem>

                                         })
                                 }
                             </List>

                     </Grid>

                </Grid>

                }
            </Dialog>

        </React.Fragment>
    )
}
export default CountNumComponent;