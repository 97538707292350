import {AppBar, ButtonBase, Dialog, Grid, IconButton, Paper, Toolbar, Typography} from "@mui/material";
import PropsWithOptionsComponent from "./PropsWithOptionsComponent";
import StickDialog from "../../../dialogs/StickDialog";
import * as React from "react";
import {CARD_HEIGHT} from "../../../Constants";
import OptionsPicker from "../../../customViews/OptionsPicker";
import CloseIcon from "@mui/icons-material/Close";
import TiresBrandsDialog from "../tiresType/TiresBrandsDialog";
import {useState} from "react";
import TiresSizeMask from "../../../customViews/TiresSizeMask";
import Keyboard09 from "../../../customViews/Keyboard09";
import TiresWeightIndexDialog from "../tiresType/TiresWeightIndexDialog";
import TiresSpeedIndexDialog from "../tiresType/TiresSpeedIndexDialog";
import {localizeType} from "../../../LocalizationFunctions";
import DotDialog from "../tiresType/DotDialog";
import AdditinalNoteDialog from "../tiresType/AdditionalNoteDialog";

function HotelPickComponentOptions({

                                       optionNames,

                                options, setOptions, customOptions,
                                tiresBrand, setTiresBrand,
                                tiresSize, setTiresSize,
                                speedIndex, setSpeedIndex,
                                weightIndex, setWeightIndex,
                                dot, setDot,
                                hotelNote, setHotelNote,


                                onClick}) {


    const [brandDialogVisible, setBrandDialogVisible] = useState(false);
    const [speedIndexDialog, setSpeedIndexDialog] = useState(false);
    const [weightIndexDialog, setWeightIndexDialog] = useState(false);
    const [tiresSizeDialog, setTiresSizeDialog] = useState(false);
    const [dotDialogOpen, setDotDialogOpen] = useState(false);
    const [additionalNoteDialogOpen, setAdditionalNoteDialogOpen] = useState(false);

    const [showKeyboard1, setShowKeyboard1] = useState(true);

    return (
        <Grid container spacing={2} style={{padding: 10}}>
            {options && options.map((subOption, index) => {
                return <React.Fragment>
                    <Grid item xs={4}>
                        <Paper elevation={6} style={{height: CARD_HEIGHT}}
                               onClick={() => {
                                   if (onClick && onClick[index]) {
                                       onClick[index]();
                                   }
                               }}
                               className={'paperCenterText'}>
                            {optionNames[index]}
                        </Paper>
                    </Grid>

                    <Grid item xs={8}>
                        <OptionsPicker options={subOption} setOptions={setOptions[index]} customOptions={customOptions}/>
                    </Grid>
                </React.Fragment>
            })}

            <Grid item xs={12}>
                <Grid container spacing={1}>


                    <Grid item xs={2}>
                        <Paper elevation={6}
                               className={'paperCenterText'} onClick={() => {
                            setTiresSizeDialog(true)
                        }}>
                            <Grid container justifyContent={'center'}>

                                <Grid item xs={12} style={{pointerEvents: 'none'}}>
                                    <TiresSizeMask value={tiresSize}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={2}>
                        <ButtonBase style={{width: '100%'}}>
                            <Paper elevation={6}
                                   style={{width: '100%'}}
                                   className={'paperCenterText'} onClick={() => {
                                setBrandDialogVisible(true)
                            }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant={'h6'}>Марка</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {tiresBrand}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </ButtonBase>
                    </Grid>

                    <Grid item xs={1.8}>
                        <ButtonBase style={{width: '100%'}}>
                            <Paper elevation={6}
                                   style={{width: '100%'}}
                                   className={'paperCenterText'} onClick={() => {
                                setSpeedIndexDialog(true)
                            }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant={'h6'}>Скоростен индекс</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {speedIndex}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </ButtonBase>
                    </Grid>

                    <Grid item xs={1.8}>
                        <ButtonBase style={{width: '100%'}}>
                            <Paper elevation={6}
                                   style={{width: '100%'}}
                                   className={'paperCenterText'} onClick={() => {
                                setWeightIndexDialog(true)
                            }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant={'h6'}>Товарен индекс</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {weightIndex}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </ButtonBase>
                    </Grid>

                    <Grid item xs={1.8}>
                        <ButtonBase style={{width: '100%'}}>
                            <Paper elevation={6}
                                   style={{width: '100%'}}
                                   className={'paperCenterText'} onClick={() => {
                                setDotDialogOpen(true)
                            }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant={'h6'}>ДОТ</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {dot}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </ButtonBase>
                    </Grid>

                    <Grid item xs={1.8}>
                        <ButtonBase style={{width: '100%'}}>
                            <Paper elevation={6}
                                   style={{width: '100%'}}
                                   className={'paperCenterText'} onClick={() => {
                                setAdditionalNoteDialogOpen(true)
                            }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant={'h6'}>Бележка към хотел</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {hotelNote}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </ButtonBase>
                    </Grid>
                </Grid>

                {/*<Grid item xs={3.5}>
                        <OptionsPicker options={data[4]} setOptions={data[5]}/>
                    </Grid>*/}

                <Dialog open={tiresSizeDialog} onClose={() => setTiresSizeDialog(false)} maxWidth={'sm'} fullWidth>

                    <Grid item xs={6}>
                        <TiresSizeMask value={tiresSize}/>
                    </Grid>

                    <Grid item xs={6}>
                        <Keyboard09 input={tiresSize} setInput={setTiresSize} onSave={() => {
                            setTiresSizeDialog(false)
                        }} showKeyboard={showKeyboard1} setShowKeyboard={setShowKeyboard1}/>
                    </Grid>
                </Dialog>

                <TiresWeightIndexDialog open={weightIndexDialog} handleClose={() => {
                    setWeightIndexDialog(false)
                }} index={weightIndex} setIndex={setWeightIndex}/>

                <TiresSpeedIndexDialog open={speedIndexDialog} handleClose={() => {
                    setSpeedIndexDialog(false)
                }} index={speedIndex} setIndex={setSpeedIndex}/>

                <DotDialog open={dotDialogOpen} handleClose={() => {
                    setDotDialogOpen(false)
                }} index={dot} setIndex={setDot}/>

                <AdditinalNoteDialog open={additionalNoteDialogOpen} handleClose={() => {
                    setAdditionalNoteDialogOpen(false)
                }} index={hotelNote} setIndex={setHotelNote}/>

            </Grid>


            <TiresBrandsDialog open={brandDialogVisible} handleClose={() => setBrandDialogVisible(false)}
                               onClick={(brand) => {
                                   setTiresBrand(brand)
                               }}/>


        </Grid>
    )
}
export default HotelPickComponentOptions;