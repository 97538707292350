import * as React from "react";
import {AppBar, ButtonBase, Dialog, Grid, IconButton, Paper, TextField, Toolbar, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {useEffect, useState} from "react";
import Keyboard09 from "../customViews/Keyboard09";

function ValuePickDialog({open, predefinedValue, handleClose, allowedLetters, onLanguageToggle, customLabel}) {

    const [value, setValue] = useState(null);
    const [showKeyboard1, setShowKeyboard1] = useState(false);

    useEffect(() => {
        if (predefinedValue) {
            setValue(predefinedValue)
        }
    }, [predefinedValue])

    return (
    <Dialog open={open} onClose={handleClose} maxWidth={'md'} fullWidth>
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                {
                    customLabel ?
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            <b>{customLabel}</b>
                        </Typography>
                        : null
                }
            </Toolbar>
        </AppBar>

        <Grid container spacing={1} style={{padding: 10}}>
            <Grid item xs={6}>
                <TextField value={value} fullWidth
                           onChange={showKeyboard1 ? () => {} : (e) => setValue(e.target.value)}/>
            </Grid>

            <Grid item xs={6}>
                <Keyboard09 customOptions={allowedLetters} input={value} setInput={setValue} onSave={() => {
                    handleClose(value)
                }} onLanguageToggle={onLanguageToggle}
                    showKeyboard={showKeyboard1}
                    setShowKeyboard={setShowKeyboard1}/>
            </Grid>
        </Grid>

    </Dialog>
    )
}
export default ValuePickDialog;