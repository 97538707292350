import * as React from "react";
import {Autocomplete, Box, Button, CircularProgress, FormHelperText, Grid, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import CreateClientDialog from "./CreateClientDialog";

function ClientPickComponent({setVehicleId, setClientId, setClientProp, artificialTextFromOutside}) {
    const [open, setOpen] = useState(false);

    const [options, setOptions] = useState([]);
    const loading = false;


    const [createClientOpen, setCreateClientOpen] = useState(null);

    const [clientTyped, setClientTyped] = useState(null);
    const [artificialText, setArtificialText] = useState(null);

    useEffect(() => {
        if (artificialTextFromOutside) {
            setArtificialText(artificialTextFromOutside.clientText)
            setClientTyped(artificialTextFromOutside.clientText)
        } else {
            setArtificialText(null)
        }
    }, [artificialTextFromOutside])

    useEffect(() => {
        let active = true;

        (async () => {
            fetch(`${process.env.REACT_APP_URL}/api/clients/search-client?`+new URLSearchParams({
                input: clientTyped,
            }), {
                method: "GET",
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((result) => result.json())
                .then((result) => {
                    setOptions([...(result)])
                })

        })();

        return () => {
            active = false;
        };
    }, [loading, clientTyped]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <React.Fragment>
            {
                artificialText ?
                    <TextField label="Клиент"
                               sx={{ width: 300 }}
                               value={clientTyped} onClick={() => {
                                   setClientId(null)
                                   setVehicleId(null)
                                   setClientTyped(null)
                                   setArtificialText(null)
                               }}/>
                    :
                    <Autocomplete
                        id="asynchronous-demo"
                        sx={{ width: 300 }}
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        onChange={(e, prop) => {
                            console.log(prop)
                            if (prop) {
                                setClientId(prop.id)
                                setVehicleId(prop.car_id)

                                if (setClientProp) {
                                    setClientProp(prop)
                                }

                            } else {
                                setClientId(null)
                                setVehicleId(null)
                            }
                        }}
                        isOptionEqualToValue={(option, value) => option && value ? option.id === value.id : false}
                        getOptionLabel={(option) => option.name + " " + (option.car ? option.car : option.telephone)}
                        renderOption={(props, option) => (
                            <Grid container {...props}>
                                <Grid item xs={12} style={{width: '100%'}}>
                                    <Typography>{option.name}</Typography>
                                </Grid>
                                <Grid item xs={12} style={{width: '100%'}}>
                                    <FormHelperText>{option.car ? option.car : option.telephone}</FormHelperText>
                                </Grid>
                            </Grid>
                        )}
                        options={options}
                        loading={loading}
                        noOptionsText={(
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                Не е намерен {clientTyped}
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => { setCreateClientOpen(true) }}
                                >
                                    Създай клиент
                                </Button>
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                label="Клиент"
                                value={clientTyped}
                                onChange={(e) => {
                                    setClientTyped(e.target.value)
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
            }


            <CreateClientDialog open={createClientOpen} handleClose={() => setCreateClientOpen(false)}
                                setClientId={setClientId} setVehicleId={setVehicleId} alreadyTyped={clientTyped}
                                setClientTyped={setClientTyped} setOptions={setOptions} setArtificialText={setArtificialText}/>
        </React.Fragment>
    )
}
export default ClientPickComponent;