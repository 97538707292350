import * as React from "react";
import {useEffect, useState} from "react";
import {Button, ButtonBase, Dialog, Grid, Paper, Typography} from "@mui/material";
import {localizeType} from "../../../LocalizationFunctions";
import TiresSizeMask from "../../../customViews/TiresSizeMask";
import Keyboard09 from "../../../customViews/Keyboard09";
import OptionsPicker from "../../../customViews/OptionsPicker";
import TiresBrandsDialog from "./TiresBrandsDialog";
import TiresWeightIndexDialog from "./TiresWeightIndexDialog";
import TiresSpeedIndexDialog from "./TiresSpeedIndexDialog";

function NewTiresSizeComponent({onSave,

                                   tiresToWorkWith,
                                   setTireToWorkWith,

                                   type,
                                   numTires, setNumTires,

                                   newTires1, setNewTires1,
                                   newTires2, setNewTires2,
                                   newTires3, setNewTires3,
                                   newTires4, setNewTires4,
                                   newTires5, setNewTires5,
                                   newTires6, setNewTires6,

                                   newTires1Brand, setNewTires1Brand,
                                   newTires2Brand, setNewTires2Brand,
                                   newTires3Brand, setNewTires3Brand,
                                   newTires4Brand, setNewTires4Brand,
                                   newTires5Brand, setNewTires5Brand,
                                   newTires6Brand, setNewTires6Brand,

                                   newProductsDate,

                                   setProductData,

                                   newTires1Num, setNewTires1Num,
                                   newTires2Num, setNewTires2Num,
                                   newTires3Num, setNewTires3Num,
                                   newTires4Num, setNewTires4Num,
                                   newTires5Num, setNewTires5Num,
                                   newTires6Num, setNewTires6Num,

                                   newTires1Type, setNewTires1Type,
                                   newTires2Type, setNewTires2Type,
                                   newTires3Type, setNewTires3Type,
                                   newTires4Type, setNewTires4Type,
                                   newTires5Type, setNewTires5Type,
                                   newTires6Type, setNewTires6Type,


                                   newTireWeightIndex1, setNewTireWeightIndex1,
                                   newTireWeightIndex2, setNewTireWeightIndex2,
                                   newTireWeightIndex3, setNewTireWeightIndex3,
                                   newTireWeightIndex4, setNewTireWeightIndex4,
                                   newTireWeightIndex5, setNewTireWeightIndex5,
                                   newTireWeightIndex6, setNewTireWeightIndex6,


                                   newTireSpeedIndex1, setNewTireSpeedIndex1,
                                   newTireSpeedIndex2, setNewTireSpeedIndex2,
                                   newTireSpeedIndex3, setNewTireSpeedIndex3,
                                   newTireSpeedIndex4, setNewTireSpeedIndex4,
                                   newTireSpeedIndex5, setNewTireSpeedIndex5,
                                   newTireSpeedIndex6, setNewTireSpeedIndex6
                               }) {

    const [keyboardOpen1, setKeyboardOpen1] = useState();
    const [keyboardOpen2, setKeyboardOpen2] = useState();
    const [keyboardOpen3, setKeyboardOpen3] = useState();
    const [keyboardOpen4, setKeyboardOpen4] = useState();
    const [keyboardOpen5, setKeyboardOpen5] = useState();
    const [keyboardOpen6, setKeyboardOpen6] = useState();

    const [weightKeyboardOpen1, setWeightKeyboardOpen1] = useState();
    const [weightKeyboardOpen2, setWeightKeyboardOpen2] = useState();
    const [weightKeyboardOpen3, setWeightKeyboardOpen3] = useState();
    const [weightKeyboardOpen4, setWeightKeyboardOpen4] = useState();
    const [weightKeyboardOpen5, setWeightKeyboardOpen5] = useState();
    const [weightKeyboardOpen6, setWeightKeyboardOpen6] = useState();

    const [speedKeyboardOpen1, setSpeedKeyboardOpen1] = useState();
    const [speedKeyboardOpen2, setSpeedKeyboardOpen2] = useState();
    const [speedKeyboardOpen3, setSpeedKeyboardOpen3] = useState();
    const [speedKeyboardOpen4, setSpeedKeyboardOpen4] = useState();
    const [speedKeyboardOpen5, setSpeedKeyboardOpen5] = useState();
    const [speedKeyboardOpen6, setSpeedKeyboardOpen6] = useState();

    const [brandDialogVisible, setBrandDialogVisible] = useState(setNewTires1);

    const [showKeyboard1, setShowKeyboard1] = useState(true);

    const iter = [
        [newTires1, setNewTires1, newTires1Brand, setNewTires1Brand, newTires1Num, setNewTires1Num, // [0-5]
            newTires1Type, setNewTires1Type, keyboardOpen1, setKeyboardOpen1, // [6-9]
            newTireWeightIndex1, setNewTireWeightIndex1, weightKeyboardOpen1, setWeightKeyboardOpen1, // [10-13]
            newTireSpeedIndex1, setNewTireSpeedIndex1, speedKeyboardOpen1, setSpeedKeyboardOpen1, // [14-17]
        ],

        [newTires2, setNewTires2, newTires2Brand, setNewTires2Brand, newTires2Num, setNewTires2Num,
            newTires2Type, setNewTires2Type, keyboardOpen2, setKeyboardOpen2,
            newTireWeightIndex2, setNewTireWeightIndex2, weightKeyboardOpen2, setWeightKeyboardOpen2,
            newTireSpeedIndex2, setNewTireSpeedIndex2, speedKeyboardOpen2, setSpeedKeyboardOpen2],

        [newTires3, setNewTires3, newTires3Brand, setNewTires3Brand, newTires3Num, setNewTires3Num,
            newTires3Type, setNewTires3Type, keyboardOpen3, setKeyboardOpen3,
            newTireWeightIndex3, setNewTireWeightIndex3, weightKeyboardOpen3, setWeightKeyboardOpen3,
            newTireSpeedIndex3, setNewTireSpeedIndex3, speedKeyboardOpen3, setSpeedKeyboardOpen3],

        [newTires4, setNewTires4, newTires4Brand, setNewTires4Brand, newTires4Num, setNewTires4Num,
            newTires4Type, setNewTires4Type, keyboardOpen4, setKeyboardOpen4,
            newTireWeightIndex4, setNewTireWeightIndex4, weightKeyboardOpen4, setWeightKeyboardOpen4,
            newTireSpeedIndex4, setNewTireSpeedIndex4, speedKeyboardOpen4, setSpeedKeyboardOpen4,
        ],

        [newTires5, setNewTires5, newTires5Brand, setNewTires5Brand, newTires5Num, setNewTires5Num,
            newTires5Type, setNewTires5Type, keyboardOpen5, setKeyboardOpen5,
            newTireWeightIndex5, setNewTireWeightIndex5, weightKeyboardOpen5, setWeightKeyboardOpen5,
            newTireSpeedIndex5, setNewTireSpeedIndex5, speedKeyboardOpen5, setSpeedKeyboardOpen5],

        [newTires6, setNewTires6, newTires6Brand, setNewTires6Brand, newTires6Num, setNewTires6Num,
            newTires6Type, setNewTires6Type, keyboardOpen6, setKeyboardOpen6,
            newTireWeightIndex6, setNewTireWeightIndex6, weightKeyboardOpen6, setWeightKeyboardOpen6,
            newTireSpeedIndex6, setNewTireSpeedIndex6, speedKeyboardOpen6, setSpeedKeyboardOpen6],
    ];

    useEffect(() => {
        if (numTires === 1) {
            setNewTires1Type(type)
        }
    }, [numTires])

    return (

        <Grid container spacing={2}>
            <Grid item xs={11}>
                <Grid container spacing={2}>

                    {iter.filter((item, index) => index < numTires).map((data, index) => {
                        return <Grid item xs={12} style={{background: tiresToWorkWith === index + 1 ? '#aeaeff' : null}}>
                            <Grid container spacing={2} onClick={() => setTireToWorkWith(index + 1)}>

                                <Grid item xs={2}>
                                    <Paper elevation={6}
                                           className={'paperCenterText'} onClick={() => {
                                               data[9](true)
                                            }}>
                                        <Grid container justifyContent={'center'}>
                                            <Grid item xs={12}>
                                                {localizeType(data[6]).toUpperCase()} {index+1}
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TiresSizeMask value={data[0]}/>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>

                                <Grid item xs={2}>
                                    <ButtonBase style={{width: '100%'}}>
                                        <Paper elevation={6}
                                               style={{width: '100%'}}
                                               className={'paperCenterText'} onClick={() => {
                                                    setBrandDialogVisible({function: data[3]})
                                               }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography variant={'h6'}>Марка</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {data[2]}
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </ButtonBase>
                                </Grid>

                                <Grid item xs={1.5}>
                                    <ButtonBase style={{width: '100%'}}>
                                        <Paper elevation={6}
                                               style={{width: '100%'}}
                                               className={'paperCenterText'} onClick={() => {
                                                    data[17](true)
                                               }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography variant={'h6'}>Скоростен индекс</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {data[14]}
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </ButtonBase>
                                </Grid>

                                <Grid item xs={1.5}>
                                    <ButtonBase style={{width: '100%'}}>
                                        <Paper elevation={6}
                                               style={{width: '100%'}}
                                               className={'paperCenterText'} onClick={() => {
                                                    data[13](true)
                                               }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography variant={'h6'}>Товарен индекс</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {data[10]}
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </ButtonBase>
                                </Grid>

                                <Grid item xs={3.5}>
                                    <OptionsPicker options={data[4]} setOptions={data[5]}/>
                                </Grid>

                                {newProductsDate[index] && newProductsDate[index].name ? <Grid item xs={1}>
                                    <Typography>{newProductsDate[index].name}</Typography>
                                </Grid> : null}

                                {index + 1 === numTires ? <Grid item xs={0.5}>
                                    <Button onClick={() => {
                                        iter[numTires][7](type)
                                        setNumTires(numTires + 1)
                                    }}>Нов ред</Button>
                                </Grid> : null}

                                {index + 1 === numTires ? <Grid style={{marginLeft: 10}} item xs={0.5}>
                                    <Button onClick={() => {
                                        data[1](null)
                                        data[3](null)
                                        data[5](null)
                                        data[7](null)
                                        data[9](null)
                                        data[11](null)
                                        data[13](null)
                                        data[15](null)
                                        data[17](null)
                                        setNumTires(numTires - 1)
                                        if (setProductData[index]) {
                                            setProductData[index](null)
                                        }
                                    }}>Изтрий</Button>
                                </Grid> : null}
                            </Grid>

                            <Dialog open={data[8]} onClose={() => data[9](false)} maxWidth={'sm'} fullWidth>

                                <Grid item xs={6}>
                                    <TiresSizeMask value={data[0]}/>
                                </Grid>

                                <Grid item xs={6}>
                                    <Keyboard09 input={data[0]} setInput={data[1]} onSave={() => {
                                        data[9](false)
                                    }} showKeyboard={showKeyboard1} setShowKeyboard={() => {}}/>
                                </Grid>
                            </Dialog>

                            <TiresWeightIndexDialog open={data[12]} handleClose={() => {
                                data[13](false)
                            }} index={data[10]} setIndex={data[11]}/>

                            <TiresSpeedIndexDialog open={data[16]} handleClose={() => {
                                data[17](false)
                            }} index={data[14]} setIndex={data[15]}/>
                        </Grid>

                    })}

                </Grid>
            </Grid>

            <TiresBrandsDialog open={brandDialogVisible} handleClose={() => setBrandDialogVisible(false)}
                onClick={(brand) => {
                    brandDialogVisible.function(brand)
                }}/>

        </Grid>
    )
}
export default NewTiresSizeComponent;