import {
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    List,
    Paper, Switch,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {CallToCellDialog} from "./CallToCellDialog";
import moment from "moment";
import {BULGARIAN_ALPHABET, CARD_HEIGHT, ENGLISH_ALPHABET} from "../../../Constants";
import EditOrderValuePickDialog from "../../../dialogs/EditOrderValuePickDialog";
import {ChangeOrderStatusDialog} from "../../../dialogs/ChangeOrderStatusDialog";
import {DatePicker, LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import bg from 'moment/locale/bg'
import ClientPickComponent from "../clientPick/ClientPickComponent";
import OrdersListDialog from "../../../dialogs/OrdersListDialog";

function OrdersAroundPanel({data, fetchData, setEditOrder,
                            givenDayMode, setGivenDayMode,
                            setConfirmOpen, setPresetClientData}) {
    moment.locale("bg"); // it is required to select default locale manually

    const [englishOrBulgarian, setEnglishOrBulgarian] = useState(true);

    const [callingOpen, setCallingOpen] = useState();
    const [checkOrderData, setCheckOrderData] = useState();

    const [descriptionPickOpen, setDescriptionPickOpen] = useState();
    const [changeOrderStatusDialogOpen, setChangeOrderStatusDialogOpen] = useState();


    const [ordersListOpen, setOrdersListOpen] = React.useState(null);
    const [vehicleId, setVehicleId] = React.useState(null);
    const [clientId, setClientId] = React.useState(null);
    const [clientProp, setClientProp] = React.useState(null);

    const [clientDetailedView, setClientDetailedView] = React.useState(false);

    useEffect(() => {
        if (clientId) {
            console.log('clientId ', clientId)
            setOrdersListOpen(true)
        } else {
            setOrdersListOpen(null)
        }
    }, [vehicleId, clientId])

    function hideOrder(id) {
        fetch(`${process.env.REACT_APP_URL}/api/tires-soft/order/hide?` + new URLSearchParams({
            id: id
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then(result => result.json())
            .then((result) => {
                fetchData()
            })
    }

    function isInProgress(order) {
        return order.order_status === 2;
    }

    function isDone(order) {
        return order.is_payed || order.order_status === 4;
    }

    function getStatus(orderStatus) {
        switch (orderStatus) {
            case 1:
                return 'Приета';
            case 2:
                return 'Работи се';
            case 3:
                return 'Готова';
            case 4:
                return 'Издадена на клиент';
            case 5:
                return 'Анулирана';
            case 6:
                return 'Сторнирана';
            default:
                return '';
        };
    }

    return (
        <Grid container style={{marginTop: 30}}>
            <Grid item xs={1.5}><Typography variant={'h6'}>Час</Typography></Grid>
            <Grid item xs={6}><Typography variant={'h6'}>Поръчки около този момент</Typography></Grid>
            <Grid item xs={2}>
                <Typography variant={'h6'}>Извикай на клетка</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant={'h6'}>Статус</Typography>
            </Grid>
            <Grid item xs={12}>
                <List>
                    {(data??[]).filter((order) => order).map((order) => {
                        let client = (order.clients??{});
                        let car = (order.car??{});
                        let calling = order.calling_car;
                        let schedule = order.schedule;

                        return <Grid container spacing={1} alignItems={'center'}>

                            <Grid item xs={1.5}>
                                {(schedule && schedule.start) ? <Grid item xs={12}>
                                    <Typography><b>{moment(schedule.start).format("HH:mm")}</b></Typography>
                                </Grid> : null}
                            </Grid>
                            <Grid item xs={6}>
                                <Paper elevation={6} style={{background: isDone(order) ? '#69ff5e' : isInProgress(order) ? '#ffc44b' : null}}
                                        onClick={() => {
                                            setDescriptionPickOpen({
                                                id: order.id,
                                                value: order.description,
                                                order: order
                                            })
                                        }}>
                                    <Grid container style={{margin: 15, padding: 10}}>


                                        <Grid item xs={isDone(order) ? 10: 12}>
                                            {clientDetailedView ? <Typography>{client.name} {client.telephone}</Typography> : null}
                                        </Grid>
                                        {
                                            (clientDetailedView && isDone(order)) ?
                                                <Grid item><IconButton onClick={(e) => {
                                                    e.stopPropagation()
                                                    e.nativeEvent.stopImmediatePropagation();
                                                    hideOrder(order.id)
                                                }}>X</IconButton></Grid>
                                                : null
                                        }
                                        <Grid item xs={!clientDetailedView && isDone(order) ? 10: 12}>
                                            <Typography>{car.make} {car.licence_plate}</Typography>
                                        </Grid>
                                        {
                                            (!clientDetailedView && isDone(order)) ?
                                                <Grid item><IconButton style={{height: 30}} onClick={(e) => {
                                                    e.stopPropagation()
                                                    e.nativeEvent.stopImmediatePropagation();
                                                    hideOrder(order.id)
                                                }}>X</IconButton></Grid>
                                                : null
                                        }

                                        <Grid item xs={12}>
                                            <FormHelperText>{order.description}</FormHelperText>
                                        </Grid>

                                     {/*   {
                                            calling ? <Grid item xs={12}>
                                                <FormHelperText style={{textAlign: 'center'}}>{calling ? `На клетка ${calling.cell_num}` : null}</FormHelperText>
                                            </Grid> : null
                                        }*/}

                                    </Grid>
                                </Paper>
                            </Grid>

                            <Grid item xs={2}>
                                <Paper elevation={6} className={"paperCenterText"} style={{height: CARD_HEIGHT, minWidth: 80, background: isDone(order) ? '#69ff5e' : null}}
                                       onClick={() => {
                                           if (!isDone(order)) {
                                               setCallingOpen(order.id)
                                               setCheckOrderData({
                                                   orderId: order.id,
                                                   vehicleId: order.vehicle_id,
                                                   warehouseId: order.warehouse_id
                                               })
                                           }
                                       }}>
                                    {
                                        calling ?
                                            <Typography variant={'h4'} style={{textAlign: 'center'}}>{calling ? `${calling.cell_num}` : null}</Typography>
                                            : null
                                    }
                                </Paper>
                            </Grid>

                            <Grid item xs={1.5}>
                                <Paper onClick={() => {
                                    setChangeOrderStatusDialogOpen(order)
                                }} elevation={6} className={"paperCenterText"} style={{height: CARD_HEIGHT, minWidth: 100, background: isDone(order) ? '#69ff5e' : null}}>
                                    {
                                        order && order.order_status ?
                                            <Typography variant={'h6'} style={{textAlign: 'center'}}>{getStatus(order.order_status)}</Typography>
                                            : null
                                    }
                                </Paper>
                            </Grid>

                        </Grid>

                    })}
                </List>

            </Grid>
            <Grid item xs={6}>
                <ToggleButtonGroup
                    value={givenDayMode === null ? 'now' : 'date'}
                    exclusive
                    onChange={(event, mode) => {
                        if (mode === 'now') {
                            setGivenDayMode(null)
                        } else {
                            setGivenDayMode(moment())
                        }
                    }}
                    aria-label="text alignment"
                >
                    <ToggleButton value="now" aria-label="left aligned">
                        Сега
                    </ToggleButton>
                    <ToggleButton value="date" aria-label="centered">
                        За ден
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <Grid item xs={6}>
                {givenDayMode ? <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="bg" localeText={{ cancelButtonLabel: "Отказ", okButtonLabel: "ОК", clearButtonLabel: "Изчисти" }}>
                    <MobileDatePicker label="За дата" value={givenDayMode}
                                onChange={(value) => {
                                    setGivenDayMode(value)
                                }}/>
                </LocalizationProvider> : null}
            </Grid>
            <Grid item xs={8}>
                <div style={{marginTop: 10}}>
                    <ClientPickComponent setVehicleId={setVehicleId} setClientId={setClientId} setClientProp={setClientProp}/>
                </div>
            </Grid>
            <Grid item xs={4}>
                <FormControlLabel control={<Switch checked={clientDetailedView} onChange={(event) => {
                    setClientDetailedView(event.target.checked);
                }} />} label="Подробно инфо за клиент" />

            </Grid>

            <CallToCellDialog
                open={callingOpen}
                handleClose={()=>setCallingOpen(null)}
                orderId={callingOpen}
                checkOrderData={checkOrderData}
                setForceRefresh={() => fetchData()}
            />


            <EditOrderValuePickDialog open={descriptionPickOpen}
                                      order={descriptionPickOpen ? descriptionPickOpen.order : null}
                                      setEditOrder={setEditOrder}
                                      predefinedValue={descriptionPickOpen ? descriptionPickOpen.value : null} handleClose={(value) => {
                if (descriptionPickOpen && value && (typeof value === 'string')) {
                    fetch(`${process.env.REACT_APP_URL}/api/tires-soft/order`, {
                        method: "PUT",
                        mode: 'cors',
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            id: descriptionPickOpen.id,
                            description: value,
                        })
                    })
                        .then(result => result.json())
                }
                setDescriptionPickOpen(null)
            }} allowedLetters={[...(englishOrBulgarian ? BULGARIAN_ALPHABET : ENGLISH_ALPHABET),
                ".", "/", "-", "+", " "]}
                                      onLanguageToggle={() => {
                                          setEnglishOrBulgarian(!englishOrBulgarian)
                                      }}/>

            <ChangeOrderStatusDialog open={changeOrderStatusDialogOpen}
                                    handleClose={() => setChangeOrderStatusDialogOpen(null)}/>


            <OrdersListDialog open={ordersListOpen} handleClose={() => {
                setOrdersListOpen(null)
                setClientId(null)
                setVehicleId(null)
            }} clientId={clientId} vehicleId={vehicleId} setEditOrder={setEditOrder}
               setConfirmOpen={setConfirmOpen} setPresetClientData={setPresetClientData}
               clientProp={clientProp}/>
        </Grid>
    );
}
export default OrdersAroundPanel;