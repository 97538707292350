import * as React from "react";
import {AppBar, ButtonBase, Dialog, Grid, IconButton, Paper, TextField, Toolbar, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {BIG_CARD_HEIGHT, CARD_HEIGHT} from "../../../Constants";
import TiresSizeMask from "../../../customViews/TiresSizeMask";
import Keyboard09 from "../../../customViews/Keyboard09";
import {useState} from "react";

function TiresWeightIndexDialog({open, handleClose, index, setIndex, onClick}) {
    const [showKeyboard1, setShowKeyboard1] = useState(true);

    return (
    <Dialog open={open} onClose={handleClose} maxWidth={'md'} fullWidth>
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar>
        </AppBar>

        <Grid container spacing={1} style={{padding: 10}}>
            <Grid item xs={6}>
                <TextField value={index}
                           onChange={showKeyboard1 ? () => {} : (e) => setIndex(e.target.value)}/>
            </Grid>

            <Grid item xs={6}>
                <Keyboard09 input={index} setInput={setIndex} onSave={() => {
                    handleClose()
                }} showKeyboard={showKeyboard1} setShowKeyboard={setShowKeyboard1}/>
            </Grid>
        </Grid>

    </Dialog>
    )
}
export default TiresWeightIndexDialog;