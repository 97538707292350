import logo from './logo.svg';
import './App.css';
import MainScreen from "./screens/MainScreen";
import {useState} from "react";
import MiniLoginScreen from "./screens/MiniLoginScreen";

function App() {
    const [logged, setLogged] = useState(false);
  return (
    <div className="App">
        {
            logged ? <MainScreen/> : <MiniLoginScreen setLogged={setLogged}/>
        }

    </div>
  );
}

export default App;
