import * as React from "react";
import {Grid, Paper, Typography} from "@mui/material";
import TiresTypeAndSizeComponent from "./TiresTypeSndSizeComponent";
import {ALL_SEASONS_TIRES, CARD_HEIGHT, SUMMER_TIRES, WINTER_TIRES} from "../../../Constants";

function TiresSelectionSimplifiedComponent({selectedTiresType, setSelectedTiresType,
                                     frontValue, rearValue, setFrontValue, setRearValue}) {
    return (
        <Grid container spacing={2}>
            {
                ['13', '14', '15', '16', '17', '18', '19', '20', '21', '22'].map((colage) => {
                    return (
                    <Grid item xs={2.4}>
                        <Paper elevation={6} style={{height: CARD_HEIGHT,
                            background: frontValue === ('000/00R'+colage) ? 'green' : 'white', cursor: 'pointer'}}
                               onClick={() => {
                                   setFrontValue('000/00R'+colage)
                                   setRearValue('000/00R'+colage)
                                   setSelectedTiresType(ALL_SEASONS_TIRES)
                               }}
                               className={'paperCenterText'}>
                            {colage}'
                        </Paper>
                    </Grid>
                )})

            }
        </Grid>
    )
}
export default TiresSelectionSimplifiedComponent;