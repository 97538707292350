import React, {useEffect, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';

function CreateClientDialog({open, handleClose, setClientId, setVehicleId, alreadyTyped, setClientTyped, setArtificialText}) {
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [carPlate, setCarPlate] = useState('');

    useEffect(() => {
        setName(alreadyTyped)
        setCarPlate(alreadyTyped)

        var re = new RegExp("^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$");
        if (re.test(alreadyTyped)) {
            setPhoneNumber(alreadyTyped)
        } else {
            setPhoneNumber("")
        }
    }, [alreadyTyped])

    const handleSubmit = () => {
        // Create a new client object with the input values

        // Make a POST API call to create a new client
        fetch(`${process.env.REACT_APP_URL}/api/clients/create-client`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name,
                telephone: phoneNumber,
                plate: carPlate,
            }),
        })
            .then(response => response.json())
            .then(data => {
                // Handle success or error response here
                setClientId(data.client_id)
                setVehicleId(data.vehicle_id)
                setClientTyped(alreadyTyped)
                setArtificialText(alreadyTyped)
                handleClose(); // Close the dialog after the API call
            })
            .catch(error => {
                // Handle error here
                console.error(error);
            });
    };


    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Създай клиент</DialogTitle>
            <DialogContent>
                <TextField
                    label="Име"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Тел. ном"
                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Рег. номер"
                    value={carPlate}
                    onChange={e => setCarPlate(e.target.value)}
                    fullWidth
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Отказ
                </Button>
                <Button disabled={!name || !phoneNumber || !carPlate} onClick={handleSubmit} color="primary">
                    Създай
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default CreateClientDialog;