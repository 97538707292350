import * as React from "react";
import {AppBar, Dialog, Grid, IconButton, Paper, Toolbar} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import OptionsPicker from "../customViews/OptionsPicker";
import {CARD_HEIGHT} from "../Constants";

function StickDialog({open, handleClose, optionNames, options, setOptions, customOptions, onClick}) {

    return (
    <Dialog open={open} onClose={handleClose} maxWidth={'md'} fullWidth>
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar>
        </AppBar>

        <Grid container spacing={2} style={{padding: 10}}>
            {options && options.map((subOption, index) => {
                return <React.Fragment>
                    <Grid item xs={4}>
                        <Paper elevation={6} style={{height: CARD_HEIGHT}}
                               onClick={() => {
                                   if (onClick && onClick[index]) {
                                       onClick[index]();
                                   }
                               }}
                               className={'paperCenterText'}>
                            {optionNames[index]}
                        </Paper>
                    </Grid>

                    <Grid item xs={8}>
                        <OptionsPicker options={subOption} setOptions={setOptions[index]} customOptions={customOptions}/>
                    </Grid>
                </React.Fragment>
            })}

        </Grid>

    </Dialog>
    )
}
export default StickDialog;