import * as React from "react";
import {useEffect, useState} from "react";
import {
    Button,
    ButtonBase,
    Checkbox,
    FormControlLabel,
    Grid,
    Paper,
    ToggleButton,
    ToggleButtonGroup, Typography
} from "@mui/material";
import TiresSelectionComponent from "./controllers/tiresType/TiresSelectionComponent";
import PropsComponent from "./controllers/props/PropsComponent";
import PropsWithOptionsComponent from "./controllers/props/PropsWithOptionsComponent";
import StickDialog from "../dialogs/StickDialog";
import CountNumComponent from "./controllers/props/CountNumComponent";
import {BIG_CARD_HEIGHT, CARD_HEIGHT, SUMMER_TIRES} from "../Constants";
import NewTiresSelectionComponent from "./controllers/tiresType/NewTiresSelectionComponent";
import AddProductsDialog from "../dialogs/finish/AddProductsDialog";
import PickOrderDialog from "../dialogs/finish/PickOrderDialog";
import MessageDialog from "./helper/MessageDialog";
import OrdersAroundPanel from "./controllers/panels/OrdersAroundPanel";
import HotelPickComponent from "./controllers/props/HotelPickComponent";
import TiresSelectionSimplifiedComponent from "./controllers/tiresType/TiresSelectionSimplifiedComponent";
import AnySearchDialog from "../dialogs/AnySearchDialog";
import OthersDialog from "../dialogs/OthersDialog";
import MechanicPickDialog from "../dialogs/MechanicPickDialog";
import moment from "moment";

function MainComponent({selectedTiresType, setSelectedTiresType,
                           frontValue, rearValue, setFrontValue, setRearValue,
                           fullReplacementOptions, setFullReplacementOptions,
                           downUpBalanceOptions, setDownUpBalanceOptions,
                           downUpOptions, setDownUpOptions,
                           balanceOptions, setBalanceOptions,
                           upOptions, setUpOptions,
                           downOptions, setDownOptions,


                           numTires, setNumTires,
                           newTires1, setNewTires1,
                           newTires2, setNewTires2,
                           newTires3, setNewTires3,
                           newTires4, setNewTires4,
                           newTires5, setNewTires5,
                           newTires6, setNewTires6,
                           newTires1ProductData, setNewTires1ProductData,
                           newTires2ProductData, setNewTires2ProductData,
                           newTires3ProductData, setNewTires3ProductData,
                           newTires4ProductData, setNewTires4ProductData,
                           newTires5ProductData, setNewTires5ProductData,
                           newTires6ProductData, setNewTires6ProductData,
                           newTires1Brand, setNewTires1Brand,
                           newTires2Brand, setNewTires2Brand,
                           newTires3Brand, setNewTires3Brand,
                           newTires4Brand, setNewTires4Brand,
                           newTires5Brand, setNewTires5Brand,
                           newTires6Brand, setNewTires6Brand,
                           newTires1Num, setNewTires1Num,
                           newTires2Num, setNewTires2Num,
                           newTires3Num, setNewTires3Num,
                           newTires4Num, setNewTires4Num,
                           newTires5Num, setNewTires5Num,
                           newTires6Num, setNewTires6Num,
                           newTires1Type, setNewTires1Type,
                           newTires2Type, setNewTires2Type,
                           newTires3Type, setNewTires3Type,
                           newTires4Type, setNewTires4Type,
                           newTires5Type, setNewTires5Type,
                           newTires6Type, setNewTires6Type,



                           numHotels, setNumHotels,

                           hotelOpen, setHotelOpen,
                           hotelDjanta, setHotelDjanta,
                           hotelNoDjanta, setHotelNoDjanta,
                           hotelTiresBrand, setHotelTiresBrand,
                           hotelTiresSize, setHotelTiresSize,
                           hotelSpeedIndex, setHotelSpeedIndex,
                           hotelWeightIndex, setHotelWeightIndex,
                           dot, setDot,
                           hotelNote, setHotelNote,

                           hotelDjanta2, setHotelDjanta2,
                           hotelNoDjanta2, setHotelNoDjanta2,
                           hotelTiresBrand2, setHotelTiresBrand2,
                           hotelTiresSize2, setHotelTiresSize2,
                           hotelSpeedIndex2, setHotelSpeedIndex2,
                           hotelWeightIndex2, setHotelWeightIndex2,
                           dot2, setDot2,
                           hotelNote2, setHotelNote2,

                           hotelDjanta3, setHotelDjanta3,
                           hotelNoDjanta3, setHotelNoDjanta3,
                           hotelTiresBrand3, setHotelTiresBrand3,
                           hotelTiresSize3, setHotelTiresSize3,
                           hotelSpeedIndex3, setHotelSpeedIndex3,
                           hotelWeightIndex3, setHotelWeightIndex3,
                           dot3, setDot3,
                           hotelNote3, setHotelNote3,


                           newTireWeightIndex1, setNewTireWeightIndex1,
                           newTireWeightIndex2, setNewTireWeightIndex2,
                           newTireWeightIndex3, setNewTireWeightIndex3,
                           newTireWeightIndex4, setNewTireWeightIndex4,
                           newTireWeightIndex5, setNewTireWeightIndex5,
                           newTireWeightIndex6, setNewTireWeightIndex6,



                           newTireSpeedIndex1, setNewTireSpeedIndex1,
                           newTireSpeedIndex2, setNewTireSpeedIndex2,
                           newTireSpeedIndex3, setNewTireSpeedIndex3,
                           newTireSpeedIndex4, setNewTireSpeedIndex4,
                           newTireSpeedIndex5, setNewTireSpeedIndex5,
                           newTireSpeedIndex6, setNewTireSpeedIndex6,



                           newSelectedTiresType, setNewSelectedTiresType,



                           stickOptions, setStickOptions,
                           gubaOptions, setGubaOptions,
                           podlojkaOptions, setPodlojkaOptions,
                           vulkanizaciqOptions, setVulkanizaciqOptions,
                           stickOptionsDialog, setStickOptionsDialog,



                           noOptions, setNoOptions, noOptionsDialog, setNoOptionsDialog,


                           ventilGumen, setVentilGumen, ventilBusov, setVentilBusov,
                           ventilMetalen, setVentilMetalen, ventilOnly, setVentilOnly, ventilTpms, setVentilTpms, ventilDatchik, setVentilDatchik, ventilDatchikDemount, setVentilDatchikDemount,
                           ventilExtension, setVentilExtension, ventilKrivka, setVentilKrivka, ventilOptionsDialog, setVentilOptionsDialog,
                           datchikDialog, setDatchikDialog, datchikOptions, setDatchikOptions,



                           plikOptions, setPlikOptions, plikOptionsDialog, setPlikOptionsDialog,


                           straightOptions, setStraightOptions, straightOptionsDialog, setStraightOptionsDialog,


                           glavinaOptions, setGlavinaOptions, glavinaOptionsDialog, setGlavinaOptionsDialog,
                           mednaPastaOptions, setMednaPastaOptions, mednaPastaOptionsDialog, setMednaPastaOptionsDialog,



                           otpadniOptions, setOtpadniOptions, otpadniOptionsDialog, setOtpadniOptionsDialog,



                            bordOptions, setBordOptions, bordOptionsDialog, setBordOptionsDialog,



                            svTailsOptions, setSvTailsOptions, svTailsOptionsDialog, setSvTailsOptionsDialog,



                            boltoveDialogOpen, setBoltoveDialogOpen, boltoveCount, setBoltoveCount, boltoveArtNom, setBoltoveArtNom, boltoveAdditionalData, setBoltoveAdditionalData,



                            sekretiDialogOpen, setSekretiDialogOpen, sekretiCount, setSekretiCount, sekretiArtNom, setSekretiArtNom, sekretiAdditionalData, setSekretiAdditionalData,

                            isJeepBus, setJeepBus,

                            additionalItems, setAdditionalItems
                       }) {

    const [givenDayMode, setGivenDayMode] = useState(null);
    const [orderAround, setOrderAround] = useState([]);

    const [toggleRaisedError, setToggleRaisedError] = useState();
    const [messageText, setMessageText] = useState();

    const [othersOpen, setOthersOpen] = useState();
    const [confirmOpen, setConfirmOpen] = useState();
    const [confirmExistingOrderOpen, setConfirmExistingOrderOpen] = useState();
    const [additionalSearchOpen, setAdditionalSearchOpen] = useState();
    const [withExistingId, setWithExistingId] = useState();

    const [editOrder, setEditOrder] = useState();

    const [presetMechanicsDialogOpen, setPresetMechanicsDialogOpen] = useState(null);
    const [presetClientData, setPresetClientData] = useState(null);
    const [presetMechanics, setPresetMechanics] = useState([]);

    function forSensorAction() {
        setDatchikDialog(true)
    }

    function resetAllProps() {
        setSelectedTiresType(SUMMER_TIRES);
        setFrontValue(null);
        setRearValue(null);

        setFullReplacementOptions([]);
        setDownUpBalanceOptions([]);
        setDownUpOptions([]);
        setBalanceOptions([]);
        setUpOptions([]);
        setDownOptions([]);

        setStickOptions([]);
        setGubaOptions([]);
        setPodlojkaOptions([]);
        setVulkanizaciqOptions([]);
        setStickOptionsDialog(false);

        setNoOptions([]);
        setNoOptionsDialog(false);

        setVentilGumen([]);
        setVentilBusov([]);
        setVentilMetalen([]);
        setVentilTpms([]);
        setVentilOnly([]);
        setVentilDatchik([]);
        setVentilDatchikDemount([]);
        setVentilExtension([]);
        setVentilKrivka([]);
        setVentilOptionsDialog(false);
        setDatchikDialog(false);
        setDatchikOptions([]);

        setPlikOptions([]);
        setPlikOptionsDialog(false);

        setStraightOptions([]);
        setStraightOptionsDialog(false);

        setGlavinaOptions([]);
        setGlavinaOptionsDialog(false);

        setMednaPastaOptions([]);
        setMednaPastaOptionsDialog(false);

        setOtpadniOptions([]);
        setOtpadniOptionsDialog(false);

        setBordOptions([]);
        setBordOptionsDialog(false);

        setSvTailsOptions([]);
        setSvTailsOptionsDialog(false);

        setBoltoveCount();
        setBoltoveArtNom();
        setBoltoveAdditionalData();
        setBoltoveDialogOpen(false);

        setSekretiCount();
        setSekretiArtNom();
        setSekretiAdditionalData();
        setSekretiDialogOpen(false);

        setNewSelectedTiresType(null);
        setNumTires(0);

        setNewTires1(null);
        setNewTires2(null);
        setNewTires3(null);
        setNewTires4(null);
        setNewTires5(null);
        setNewTires6(null);

        setNewTires1ProductData(null);
        setNewTires2ProductData(null);
        setNewTires3ProductData(null);
        setNewTires4ProductData(null);
        setNewTires5ProductData(null);
        setNewTires6ProductData(null);

        setNumHotels(1);

        setHotelOpen(null);
        setHotelDjanta([]);
        setHotelNoDjanta([]);
        setHotelTiresBrand([]);
        setHotelTiresSize("");
        setHotelSpeedIndex([]);
        setHotelWeightIndex([]);
        setDot(null);
        setHotelNote(null);

        setHotelDjanta2([]);
        setHotelNoDjanta2([]);
        setHotelTiresBrand2([]);
        setHotelTiresSize2("");
        setHotelSpeedIndex2([]);
        setHotelWeightIndex2([]);
        setDot2(null);
        setHotelNote2(null);

        setHotelDjanta3([]);
        setHotelNoDjanta3([]);
        setHotelTiresBrand3([]);
        setHotelTiresSize3("");
        setHotelSpeedIndex3([]);
        setHotelWeightIndex3([]);
        setDot3(null);
        setHotelNote3(null);

        setNewTires1Brand([]);
        setNewTires2Brand([]);
        setNewTires3Brand([]);
        setNewTires4Brand([]);
        setNewTires5Brand([]);
        setNewTires6Brand([]);

        setNewTires1Num([]);
        setNewTires2Num([]);
        setNewTires3Num([]);
        setNewTires4Num([]);
        setNewTires5Num([]);
        setNewTires6Num([]);

        setNewTires1Type(null);
        setNewTires2Type(null);
        setNewTires3Type(null);
        setNewTires4Type(null);
        setNewTires5Type(null);
        setNewTires6Type(null);

        setNewTireWeightIndex1(null);
        setNewTireWeightIndex2(null);
        setNewTireWeightIndex3(null);
        setNewTireWeightIndex4(null);
        setNewTireWeightIndex5(null);
        setNewTireWeightIndex6(null);

        setNewTireSpeedIndex1(null);
        setNewTireSpeedIndex2(null);
        setNewTireSpeedIndex3(null);
        setNewTireSpeedIndex4(null);
        setNewTireSpeedIndex5(null);
        setNewTireSpeedIndex6(null);

        setJeepBus(null);

        setAdditionalItems([]);
    }

    function maskString(input) {
        if (input && input.length >= 3) {

            const firstPart = input.slice(0, 3);
            const remainingPart = input.slice(3, 5);
            const lastPart = input.slice(5);
            return `${firstPart}/${remainingPart}${lastPart ? 'R' : ''}${lastPart}`;
        } else {

            return input;
        }
    }

    const fetchData = async () => {
        fetch(givenDayMode ?
            `${process.env.REACT_APP_URL}/api/tires-soft/order/date?` + new URLSearchParams({
                warehouseId: 1,
                date: givenDayMode ? givenDayMode.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
            })
            :
            `${process.env.REACT_APP_URL}/api/tires-soft/order/nearby?` + new URLSearchParams({
            warehouseId: 1
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then(result => result.json())
            .then((result) => {
                let toSet = result;
                toSet.sort((a, b) => {
                    const timestampA = a && a.schedule && a.schedule?.start ? new Date(a.schedule.start).getTime() : 0;
                    const timestampB = b && b.schedule && b.schedule?.start ? new Date(b.schedule.start).getTime() : 0;

                    return timestampA - timestampB;
                })
                setOrderAround(toSet)
            })
    }

    useEffect(() => {
        // Fetch data initially
        fetchData();

        // Set up the interval to fetch data every 5 seconds
        const intervalId = setInterval(fetchData, 5000);

        // Clear the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, [givenDayMode])

    // 1. Смяна гуми
    function fullReplacementIds(colage) {
        switch (colage) {
            case 13: return { id: 3591, name: "Смяна гуми 13"}
            case 14: return { id: 3592, name: "Смяна гуми 14"}
            case 15: return { id: 3593, name: "Смяна гуми 15"}
            case 16: return { id: 3594, name: "Смяна гуми 16"}
            case 17: return { id: 3595, name: "Смяна гуми 17"}
            case 18: return { id: 3596, name: "Смяна гуми 18"}
            case 19: return { id: 3597, name: "Смяна гуми 19"}
            case 20: return { id: 3598, name: "Смяна гуми 20"}
            case 21: return { id: 5265, name: "Смяна гуми 21"}
            case 22: return { id: 5268, name: "Смяна гуми 22"}
        }
    }
    function fullReplacementIdsJeep(colage) {
        switch (colage) {
            case 14: return { id: 3600, name: "Смяна гуми Джип/Бус 14"}
            case 15: return { id: 3601, name: "Смяна гуми Джип/Бус 15"}
            case 16: return { id: 3602, name: "Смяна гуми Джип/Бус 16"}
            case 17: return { id: 3603, name: "Смяна гуми Джип/Бус 17"}
            case 18: return { id: 3604, name: "Смяна гуми Джип/Бус 18"}
            case 19: return { id: 3605, name: "Смяна гуми Джип/Бус 19"}
            case 20: return { id: 3606, name: "Смяна гуми Джип/Бус 20"}
            case 21: return { id: 5425, name: "Смяна гуми Джип/Бус 21"}
            case 22: return { id: 5426, name: "Смяна гуми Джип/Бус 22"}
        }
    }
    // !!!---- 1. Смяна гуми



    // 2. Сваляне/Качване и Баланс
    function downUpBalanceOptionsIds(colage) {
        switch (colage) {
            case 13: return { id: 6690, name: "Сваляне/Качване и Баланс 13"}
            case 14: return { id: 6691, name: "Сваляне/Качване и Баланс 14"}
            case 15: return { id: 6693, name: "Сваляне/Качване и Баланс 15"}
            case 16: return { id: 6692, name: "Сваляне/Качване и Баланс 16"}
            case 17: return { id: 6694, name: "Сваляне/Качване и Баланс 17"}
            case 18: return { id: 6695, name: "Сваляне/Качване и Баланс 18"}
            case 19: return { id: 6696, name: "Сваляне/Качване и Баланс 19"}
            case 20: return { id: 6697, name: "Сваляне/Качване и Баланс 20"}
            case 21: return { id: 6698, name: "Сваляне/Качване и Баланс 21"}
        }
    }
    function downUpBalanceOptionsIdsJeep(colage) {
        switch (colage) {
            case 13: return { id: 8124, name: "Сваляне/Качване и Баланс Джип/Бус 14"}
            case 15: return { id: 8274, name: "Сваляне/Качване и Баланс Джип/Бус 15"}
            case 16: return { id: 8275, name: "Сваляне/Качване и Баланс Джип/Бус 16"}
            case 17: return { id: 8276, name: "Сваляне/Качване и Баланс Джип/Бус 17"}
            case 18: return { id: 8277, name: "Сваляне/Качване и Баланс Джип/Бус 18"}
            case 19: return { id: 8278, name: "Сваляне/Качване и Баланс Джип/Бус 19"}
            case 20: return { id: 8279, name: "Сваляне/Качване и Баланс Джип/Бус 20"}
            case 21: return { id: 8280, name: "Сваляне/Качване и Баланс Джип/Бус 21"}
        }
    }
    // !!!---- 2.Сваляне/Качване и Баланс



    // 3. Сваляне/Качване
    function downUpOptionsIds(colage) {
        switch (colage) {
            case 13: return { id: 3607, name: "Сваляне/Качване 13"}
            case 14: return { id: 3608, name: "Сваляне/Качване 14"}
            case 15: return { id: 3609, name: "Сваляне/Качване 15"}
            case 16: return { id: 3610, name: "Сваляне/Качване 16"}
            case 17: return { id: 3611, name: "Сваляне/Качване 17"}
            case 18: return { id: 3612, name: "Сваляне/Качване 18"}
            case 19: return { id: 3613, name: "Сваляне/Качване 19"}
            case 20: return { id: 3614, name: "Сваляне/Качване 20"}
            case 21: return { id: 5306, name: "Сваляне/Качване 21"}
            case 22: return { id: 5307, name: "Сваляне/Качване 22"}
        }
    }
    function downUpOptionsIdsJeep(colage) {
        switch (colage) {
            case 14: return { id: 3641, name: "Сваляне/Качване Джип/Бус 14"}
            case 15: return { id: 3642, name: "Сваляне/Качване Джип/Бус 15"}
            case 16: return { id: 3643, name: "Сваляне/Качване Джип/Бус 16"}
            case 17: return { id: 3644, name: "Сваляне/Качване Джип/Бус 17"}
            case 18: return { id: 3645, name: "Сваляне/Качване Джип/Бус 18"}
            case 19: return { id: 3646, name: "Сваляне/Качване Джип/Бус 19"}
            case 20: return { id: 3647, name: "Сваляне/Качване Джип/Бус 20"}
            case 21: return { id: 5433, name: "Сваляне/Качване Джип/Бус 21"}
            case 22: return { id: 5437, name: "Сваляне/Качване Джип/Бус 22"}
        }
    }
    // !!!!---- 3. Сваляне/Качване



    // 4. Баланс
    function balanceIds(colage) {
        switch (colage) {
            case 13: return { id: 3632, name: "Баланс 13"}
            case 14: return { id: 3633, name: "Баланс 14"}
            case 15: return { id: 3634, name: "Баланс 15"}
            case 16: return { id: 3635, name: "Баланс 16"}
            case 17: return { id: 3636, name: "Баланс 17"}
            case 18: return { id: 3637, name: "Баланс 18"}
            case 19: return { id: 3638, name: "Баланс 19"}
            case 20: return { id: 3639, name: "Баланс 20"}
            case 21: return { id: 5399, name: "Баланс 21"}
            case 22: return { id: 5400, name: "Баланс 22"}
        }
    }
    function balanceIdsJeep(colage) {
        switch (colage) {
            case 14: return { id: 3666, name: "Баланс Джип/Бус 14"}
            case 15: return { id: 3667, name: "Баланс Джип/Бус 15"}
            case 16: return { id: 3669, name: "Баланс Джип/Бус 16"}
            case 17: return { id: 3670, name: "Баланс Джип/Бус 17"}
            case 18: return { id: 3671, name: "Баланс Джип/Бус 18"}
            case 19: return { id: 3673, name: "Баланс Джип/Бус 19"}
            case 20: return { id: 3672, name: "Баланс Джип/Бус 20"}
            case 21: return { id: 5762, name: "Баланс Джип/Бус 21"}
            case 22: return { id: 5765, name: "Баланс Джип/Бус 22"}
        }
    }
    // !!!!---- 4. Баланс



    // 5. Монтаж
    function upIds(colage) {
        switch (colage) {
            case 13: return { id: 3615, name: "Монтаж 13"}
            case 14: return { id: 3616, name: "Монтаж 14"}
            case 15: return { id: 3617, name: "Монтаж 15"}
            case 16: return { id: 3618, name: "Монтаж 16"}
            case 17: return { id: 3619, name: "Монтаж 17"}
            case 18: return { id: 3620, name: "Монтаж 18"}
            case 19: return { id: 3621, name: "Монтаж 19"}
            case 20: return { id: 3622, name: "Монтаж 20"}
            case 21: return { id: 5366, name: "Монтаж 21"}
            case 22: return { id: 5367, name: "Монтаж 22"}
        }
    }
    function upIdsJeep(colage) {
        switch (colage) {
            case 14: return { id: 3649, name: "Монтаж Джип/Бус 14"}
            case 15: return { id: 3650, name: "Монтаж Джип/Бус 15"}
            case 16: return { id: 3652, name: "Монтаж Джип/Бус 16"}
            case 17: return { id: 3653, name: "Монтаж Джип/Бус 17"}
            case 18: return { id: 3656, name: "Монтаж Джип/Бус 18"}
            case 19: return { id: 3654, name: "Монтаж Джип/Бус 19"}
            case 20: return { id: 3655, name: "Монтаж Джип/Бус 20"}
            case 21: return { id: 5726, name: "Монтаж Джип/Бус 21"}
            case 22: return { id: 5733, name: "Монтаж Джип/Бус 22"}
        }
    }
    // !!!!---- 5. Монтаж



    // 6. Демонтаж
    function downIds(colage) {
        switch (colage) {
            case 13: return { id: 3623, name: "Демонтаж 13"}
            case 14: return { id: 3624, name: "Демонтаж 14"}
            case 15: return { id: 3625, name: "Демонтаж 15"}
            case 16: return { id: 3626, name: "Демонтаж 16"}
            case 17: return { id: 3627, name: "Демонтаж 17"}
            case 18: return { id: 3629, name: "Демонтаж 18"}
            case 19: return { id: 3630, name: "Демонтаж 19"}
            case 20: return { id: 3631, name: "Демонтаж 20"}
            case 21: return { id: 5395, name: "Демонтаж 21"}
            case 22: return { id: 5398, name: "Демонтаж 22"}
        }
    } function downIdsJeep(colage) {
        switch (colage) {
            case 14: return { id: 3658, name: "Демонтаж Джип/Бус 14"}
            case 15: return { id: 3659, name: "Демонтаж Джип/Бус 15"}
            case 16: return { id: 3660, name: "Демонтаж Джип/Бус 16"}
            case 17: return { id: 3661, name: "Демонтаж Джип/Бус 17"}
            case 18: return { id: 3662, name: "Демонтаж Джип/Бус 18"}
            case 19: return { id: 3663, name: "Демонтаж Джип/Бус 19"}
            case 20: return { id: 3664, name: "Демонтаж Джип/Бус 20"}
            case 21: return { id: 5755, name: "Демонтаж Джип/Бус 21"}
            case 22: return { id: 5751, name: "Демонтаж Джип/Бус 22"}
        }
    }
    // !!!!---- 6. Демонтаж



    // 7. Помпане с азот
    function noIds(colage) {
        switch (colage) {
            case 13: return { id: 6024, name: "Помпане с азот 13\""}
            case 14: return { id: 6735, name: "Помпане с азот 14\""}
            case 15: return { id: 6157, name: "Помпане с азот 15\""}
            case 16: return { id: 6074, name: "Помпане с азот 16\""}
            case 17: return { id: 6573, name: "Помпане с азот 17\""}
            case 18: return { id: 6685, name: "Помпане с азот 18\""}
            case 19: return { id: 6748, name: "Помпане с азот 19\""}
            case 20: return { id: 6749, name: "Помпане с азот 20\""}
            case 21: return { id: 6750, name: "Помпане с азот 21\""}
        }
    }
    function noIdsJeep(colage) {
        switch (colage) {
            case 13: return { id: 6762, name: "Помпане с азот джип 13\""}
            case 14: return { id: 6763, name: "Помпане с азот джип 14\""}
            case 15: return { id: 6764, name: "Помпане с азот джип 15\""}
            case 16: return { id: 6765, name: "Помпане с азот джип 16\""}
            case 17: return { id: 6025, name: "Помпане с азот джип 17\""}
            case 18: return { id: 6766, name: "Помпане с азот джип 18\""}
            case 19: return { id: 6767, name: "Помпане с азот джип 19\""}
            case 20: return { id: 6768, name: "Помпане с азот джип 20\""}
            case 21: return { id: 6769, name: "Помпане с азот джип 21\""}
        }
    }
    // !!!!---- 7. Помпане с азот




    // 8. Изправяне на джанта
    function straightIds(colage) {
        switch (colage) {
            case 13: return { id: 3674, name: "Изправяне на джанта 13\""}
            case 14: return { id: 3675, name: "Изправяне на джанта 14\""}
            case 15: return { id: 3676, name: "Изправяне на джанта 15\""}
            case 16: return { id: 3677, name: "Изправяне на джанта 16\""}
            case 17: return { id: 3678, name: "Изправяне на джанта 17\""}
            case 18: return { id: 3679, name: "Изправяне на джанта 18\""}
            case 19: return { id: 3680, name: "Изправяне на джанта 19\""}
            case 20: return { id: 3681, name: "Изправяне на джанта 20\""}
            case 21: return { id: 5768, name: "Изправяне на джанта 21\""}
            case 22: return { id: 5769, name: "Изправяне на джанта 22\""}
        }
    } function straightIdsJeep(colage) {
        switch (colage) {
            case 13: return { id: 5771, name: "Изправяне на джанта SUV/бус 13\""}
            case 14: return { id: 5772, name: "Изправяне на джанта SUV/бус 14\""}
            case 15: return { id: 5773, name: "Изправяне на джанта SUV/бус 15\""}
            case 16: return { id: 5775, name: "Изправяне на джанта SUV/бус 16\""}
            case 17: return { id: 5776, name: "Изправяне на джанта SUV/бус 17\""}
            case 18: return { id: 5777, name: "Изправяне на джанта SUV/бус 18\""}
            case 19: return { id: 5779, name: "Изправяне на джанта SUV/бус 19\""}
            case 20: return { id: 5780, name: "Изправяне на джанта SUV/бус 20\""}
            case 21: return { id: 5781, name: "Изправяне на джанта SUV/бус 21\""}
            case 22: return { id: 5782, name: "Изправяне на джанта SUV/бус 22\""}
        }
    }
    // !!!!---- 8. Изправяне на джанта




    // 9. Хотел с джанта
    function hotelDjantaIds(colage) {
        let description = `${maskString(hotelTiresSize??"")} ${hotelTiresBrand??""} ${hotelWeightIndex??""} ${hotelSpeedIndex??""} ${dot??""} ${hotelNote??""}`;
        let description2 = ` ${maskString(hotelTiresSize2??"")} ${hotelTiresBrand2??""} ${hotelWeightIndex2??""} ${hotelSpeedIndex2??""} ${dot2??""} ${hotelNote2??""}`;
        let description3 = ` ${maskString(hotelTiresSize3??"")} ${hotelTiresBrand3??""} ${hotelWeightIndex3??""} ${hotelSpeedIndex3??""} ${dot3??""} ${hotelNote3??""}`;
        description = description + description2 + description3;

        switch (colage) {
            case 13: return { id: 10818, name: "Хотел 13\" с джанта", description: description}
            case 14: return { id: 10819, name: "Хотел 14\" с джанта", description: description}
            case 15: return { id: 10820, name: "Хотел 15\" с джанта", description: description}
            case 16: return { id: 10821, name: "Хотел 16\" с джанта", description: description}
            case 17: return { id: 10822, name: "Хотел 17\" с джанта", description: description}
            case 18: return { id: 10823, name: "Хотел 18\" с джанта", description: description}
            case 19: return { id: 10824, name: "Хотел 19\" с джанта", description: description}
            case 20: return { id: 10825, name: "Хотел 20\" с джанта", description: description}
            case 21: return { id: 10826, name: "Хотел 21\" с джанта", description: description}
        }
    }
    function hotelDjantaIdsForSingle(colage, hotelTiresSize, hotelTiresBrand, hotelWeightIndex, hotelSpeedIndex, dot, hotelNote) {
        let description = `${maskString(hotelTiresSize??"")} ${hotelTiresBrand??""} ${hotelWeightIndex??""} ${hotelSpeedIndex??""} ${dot??""} ${hotelNote??""}`;

        switch (colage) {
            case 13: return { id: 10818, name: "Хотел 13\" с джанта", description: description}
            case 14: return { id: 10819, name: "Хотел 14\" с джанта", description: description}
            case 15: return { id: 10820, name: "Хотел 15\" с джанта", description: description}
            case 16: return { id: 10821, name: "Хотел 16\" с джанта", description: description}
            case 17: return { id: 10822, name: "Хотел 17\" с джанта", description: description}
            case 18: return { id: 10823, name: "Хотел 18\" с джанта", description: description}
            case 19: return { id: 10824, name: "Хотел 19\" с джанта", description: description}
            case 20: return { id: 10825, name: "Хотел 20\" с джанта", description: description}
            case 21: return { id: 10826, name: "Хотел 21\" с джанта", description: description}
        }
    }
    // !!!!---- 9. Хотел с джанта
    // 10. Хотел без джанта
    function hotelNoDjantaIds(colage) {
        let description = `${maskString(hotelTiresSize??"")} ${hotelTiresBrand??""} ${hotelWeightIndex??""} ${hotelSpeedIndex??""} ${dot??""} ${hotelNote??""}`;
        let description2 = ` ${maskString(hotelTiresSize2??"")} ${hotelTiresBrand2??""} ${hotelWeightIndex2??""} ${hotelSpeedIndex2??""} ${dot2??""} ${hotelNote2??""}`;
        let description3 = ` ${maskString(hotelTiresSize3??"")} ${hotelTiresBrand3??""} ${hotelWeightIndex3??""} ${hotelSpeedIndex3??""} ${dot3??""} ${hotelNote3??""}`;
        description = description + description2 + description3;

        switch (colage) {
            case 13: return { id: 10809, name: "Хотел 13\" без джанта", description: description}
            case 14: return { id: 10810, name: "Хотел 14\" без джанта", description: description}
            case 15: return { id: 10811, name: "Хотел 15\" без джанта", description: description}
            case 16: return { id: 10812, name: "Хотел 16\" без джанта", description: description}
            case 17: return { id: 10813, name: "Хотел 17\" без джанта", description: description}
            case 18: return { id: 10814, name: "Хотел 18\" без джанта", description: description}
            case 19: return { id: 10815, name: "Хотел 19\" без джанта", description: description}
            case 20: return { id: 10816, name: "Хотел 20\" без джанта", description: description}
            case 21: return { id: 10817, name: "Хотел 21\" без джанта", description: description}
        }
    }
    function hotelNoDjantaIdsForSingle(colage, hotelTiresSize, hotelTiresBrand, hotelWeightIndex, hotelSpeedIndex, dot, hotelNote) {
        let description = `${maskString(hotelTiresSize??"")} ${hotelTiresBrand??""} ${hotelWeightIndex??""} ${hotelSpeedIndex??""} ${dot??""} ${hotelNote??""}`;

        switch (colage) {
            case 13: return { id: 10809, name: "Хотел 13\" без джанта", description: description}
            case 14: return { id: 10810, name: "Хотел 14\" без джанта", description: description}
            case 15: return { id: 10811, name: "Хотел 15\" без джанта", description: description}
            case 16: return { id: 10812, name: "Хотел 16\" без джанта", description: description}
            case 17: return { id: 10813, name: "Хотел 17\" без джанта", description: description}
            case 18: return { id: 10814, name: "Хотел 18\" без джанта", description: description}
            case 19: return { id: 10815, name: "Хотел 19\" без джанта", description: description}
            case 20: return { id: 10816, name: "Хотел 20\" без джанта", description: description}
            case 21: return { id: 10817, name: "Хотел 21\" без джанта", description: description}
        }
    }
    // !!!!---- 10. Хотел без джанта

    function routeByProductId(products, options, productId, productName) {
        let num = options.reduce((a, b) => a + b, 0);

        products.push({
            id: productId,
            name: productName,
            quantity: num,
        })
    }

    function routeByColage(products, options, productFunction, productFunctionJeep) {
        let numTires = options.reduce((a, b) => a + b, 0);

        let colage;
        if (frontValue !== rearValue) {
            let colage1 = Number(frontValue.slice(-2));
            let element1 = isJeepBus === 'jeep' ? productFunctionJeep(colage1) : productFunction(colage1)
            element1.quantity = Math.floor(numTires / 2);
            products.push(element1)

            let colage2 = Number(rearValue.slice(-2));
            let element2 = isJeepBus === 'jeep' ? productFunctionJeep(colage2) : productFunction(colage2)
            element2.quantity = numTires - element1.quantity;
            products.push(element2)

        } else {
            colage = Number(rearValue.slice(-2));
            let element = isJeepBus === 'jeep' ? productFunctionJeep(colage) : productFunction(colage);
            element.quantity = numTires;
            products.push(element)
        }
    }

    function routeByCustomColage(products, options, productFunction, productFunctionJeep, colage, hotelTiresSize, hotelTiresBrand, hotelWeightIndex, hotelSpeedIndex, dot, hotelNote) {
        let numTires = options.reduce((a, b) => a + b, 0);

        let element = isJeepBus === 'jeep'
            ? productFunctionJeep(colage, hotelTiresSize, hotelTiresBrand, hotelWeightIndex, hotelSpeedIndex, dot, hotelNote)
            : productFunction(colage, hotelTiresSize, hotelTiresBrand, hotelWeightIndex, hotelSpeedIndex, dot, hotelNote);
        element.quantity = numTires;
        products.push(element)
    }

    function computeProducts() {
        let products = [];
        console.log(selectedTiresType)
        console.log(frontValue)
        console.log(rearValue)

        if (fullReplacementOptions && fullReplacementOptions.length > 0) {
            routeByColage(products, fullReplacementOptions, fullReplacementIds, fullReplacementIdsJeep)
        }

        if (downUpBalanceOptions && downUpBalanceOptions.length > 0) {
            routeByColage(products, downUpBalanceOptions, downUpBalanceOptionsIds, downUpBalanceOptionsIdsJeep)
        }

        if (downUpOptions && downUpOptions.length > 0) {
            routeByColage(products, downUpOptions, downUpOptionsIds, downUpOptionsIdsJeep)
        }

        if (balanceOptions && balanceOptions.length > 0) {
            routeByColage(products, balanceOptions, balanceIds, balanceIdsJeep)
        }

        if (upOptions && upOptions.length > 0) {
            routeByColage(products, upOptions, upIds, upIdsJeep)
        }

        if (downOptions && downOptions.length > 0) {
            routeByColage(products, downOptions, downIds, downIdsJeep)
        }

        if (hotelDjanta && hotelDjanta.length > 0) {
            const lastPart = hotelTiresSize.slice(5);
            lastPart ?
                routeByCustomColage(products, [...hotelDjanta],
                    hotelDjantaIdsForSingle, hotelDjantaIdsForSingle, Number(lastPart), hotelTiresSize, hotelTiresBrand, hotelWeightIndex, hotelSpeedIndex, dot, hotelNote)
                :
                routeByColage(products, [...hotelDjanta, ...hotelDjanta2, ...hotelDjanta3],
                    hotelDjantaIds, hotelDjantaIds);
        }

        if (hotelDjanta2 && hotelDjanta2.length > 0) {
            const lastPart = hotelTiresSize2.slice(5);
            lastPart ?
                routeByCustomColage(products, [...hotelDjanta2],
                    hotelDjantaIdsForSingle, hotelDjantaIdsForSingle, Number(lastPart), hotelTiresSize2, hotelTiresBrand2, hotelWeightIndex2, hotelSpeedIndex2, dot2, hotelNote2)
                :
                routeByColage(products, [...hotelDjanta, ...hotelDjanta2, ...hotelDjanta3],
                    hotelDjantaIds, hotelDjantaIds);
        }

        if (hotelDjanta3 && hotelDjanta3.length > 0) {
            const lastPart = hotelTiresSize3.slice(5);
            lastPart ?
                routeByCustomColage(products, [...hotelDjanta3],
                    hotelDjantaIdsForSingle, hotelDjantaIdsForSingle, Number(lastPart), hotelTiresSize3, hotelTiresBrand3, hotelWeightIndex3, hotelSpeedIndex3, dot3, hotelNote3)
                :
                routeByColage(products, [...hotelDjanta, ...hotelDjanta2, ...hotelDjanta3],
                    hotelDjantaIds, hotelDjantaIds);
        }

        if (hotelNoDjanta && hotelNoDjanta.length > 0) {
            const lastPart = hotelTiresSize.slice(5);
            lastPart ?
                routeByCustomColage(products, [...hotelNoDjanta],
                    hotelNoDjantaIdsForSingle, hotelNoDjantaIdsForSingle, Number(lastPart), hotelTiresSize, hotelTiresBrand, hotelWeightIndex, hotelSpeedIndex, dot, hotelNote)
                :
                routeByColage(products, [...hotelNoDjanta, ...hotelNoDjanta2, ...hotelNoDjanta3],
                    hotelNoDjantaIds, hotelNoDjantaIds)
        }

        if (hotelNoDjanta2 && hotelNoDjanta2.length > 0) {
            const lastPart = hotelTiresSize2.slice(5);
            lastPart ?
                routeByCustomColage(products, [...hotelNoDjanta2],
                    hotelNoDjantaIdsForSingle, hotelNoDjantaIdsForSingle, Number(lastPart), hotelTiresSize2, hotelTiresBrand2, hotelWeightIndex2, hotelSpeedIndex2, dot2, hotelNote2)
                :
                routeByColage(products, [...hotelNoDjanta, ...hotelNoDjanta2, ...hotelNoDjanta3],
                    hotelNoDjantaIds, hotelNoDjantaIds)
        }

        if (hotelNoDjanta3 && hotelNoDjanta3.length > 0) {
            const lastPart = hotelTiresSize3.slice(5);
            lastPart ?
                routeByCustomColage(products, [...hotelNoDjanta3],
                    hotelNoDjantaIdsForSingle, hotelNoDjantaIdsForSingle, Number(lastPart), hotelTiresSize3, hotelTiresBrand3, hotelWeightIndex3, hotelSpeedIndex3, dot3, hotelNote3)
                :
                routeByColage(products, [...hotelNoDjanta, ...hotelNoDjanta2, ...hotelNoDjanta3],
                    hotelNoDjantaIds, hotelNoDjantaIds)
        }

        if (stickOptions && stickOptions.length > 0) {
            routeByProductId(products, stickOptions, 5788, 'Лепенка')
        }

        if (gubaOptions && gubaOptions.length > 0) {
            routeByProductId(products, gubaOptions, 7131, 'Гъба')
        }

        if (podlojkaOptions && podlojkaOptions.length > 0) {
            routeByProductId(products, podlojkaOptions, 5789, 'Подложка')
        }

        if (vulkanizaciqOptions && vulkanizaciqOptions.length > 0) {
            routeByProductId(products, vulkanizaciqOptions, 3695, 'Вулканизация')
        }

        if (noOptions && noOptions.length > 0) {
            routeByColage(products, noOptions, noIds, noIdsJeep)
        }

        if (ventilGumen && ventilGumen.length > 0) {
            routeByProductId(products, ventilGumen, 5790, 'Гумен вентил')
        }

        if (ventilBusov && ventilBusov.length > 0) {
            routeByProductId(products, ventilBusov, 7505, 'Гумен бусов')
        }

        if (ventilMetalen && ventilMetalen.length > 0) {
            routeByProductId(products, ventilMetalen, 16129, 'Метален вентил')
        }

        if (ventilOnly && ventilOnly.length > 0) {
            routeByProductId(products, ventilOnly, 8862, 'Вентил за датчик само')
        }

        if (ventilTpms && ventilTpms.length > 0) {
            routeByProductId(products, ventilTpms, 15848, 'Вентил за датчик + датчик - TPMS')
        }

        if (ventilDatchik && ventilDatchik.length > 0) {
            routeByProductId(products, ventilDatchik, 16100, 'Монтаж на вентил с датчик')
        }

        if (ventilDatchikDemount && ventilDatchikDemount.length > 0) {
            routeByProductId(products, ventilDatchikDemount, 16101, 'Демонтаж на вентил с датчик')
        }

        if (ventilExtension && ventilExtension.length > 0) {
            routeByProductId(products, ventilExtension, 10169, 'Пластмасово удължение за вентил')
        }

        if (ventilKrivka && ventilKrivka.length > 0) {
            routeByProductId(products, ventilKrivka, 10168, 'Кривка за вентил')
        }

        if (plikOptions && plikOptions.length > 0) {
            routeByProductId(products, plikOptions, 6146, 'Плик за гуми')
        }

        if (straightOptions && straightOptions.length > 0) {
            routeByColage(products, straightOptions, straightIds, straightIdsJeep)
        }

        if (glavinaOptions && glavinaOptions.length > 0) {
            routeByProductId(products, glavinaOptions, 3707, 'Почистване на главина')
        }

        if (mednaPastaOptions && mednaPastaOptions.length > 0) {
            routeByProductId(products, mednaPastaOptions, 9362, 'МЕДНА ПАСТА')
        }

        if (otpadniOptions && otpadniOptions.length > 0) {
            routeByProductId(products, otpadniOptions, 7561, 'Отпадни гуми')
        }

        if (bordOptions && bordOptions.length > 0) {
            routeByProductId(products, bordOptions, 7504, 'Почистване на борд')
        }

        if (svTailsOptions && svTailsOptions.length > 0) {
            routeByProductId(products, svTailsOptions, 8380, 'Свински опашки')
        }

        if (boltoveCount && boltoveArtNom && boltoveAdditionalData) {
            products.push(boltoveAdditionalData)
        }

        if (sekretiCount && sekretiArtNom && sekretiAdditionalData) {
            products.push(sekretiAdditionalData)
        }

        const NEW_TIRES_DESCR = 'СМЕНИ ЦЕНА! НОВИ ГУМИ!!!';
        if (newTires1ProductData) {
            newTires1ProductData.price = 1000;
            newTires1ProductData.description = NEW_TIRES_DESCR;
            products.push(newTires1ProductData)
        }

        if (newTires2ProductData) {
            newTires2ProductData.price = 1000;
            newTires2ProductData.description = NEW_TIRES_DESCR;
            products.push(newTires2ProductData)
        }

        if (newTires3ProductData) {
            newTires3ProductData.price = 1000;
            newTires3ProductData.description = NEW_TIRES_DESCR;
            products.push(newTires3ProductData)
        }

        if (newTires4ProductData) {
            newTires4ProductData.price = 1000;
            newTires4ProductData.description = NEW_TIRES_DESCR;
            products.push(newTires4ProductData)
        }

        if (newTires5ProductData) {
            newTires5ProductData.price = 1000;
            newTires5ProductData.description = NEW_TIRES_DESCR;
            products.push(newTires5ProductData)
        }

        if (newTires6ProductData) {
            newTires6ProductData.price = 1000;
            newTires6ProductData.description = NEW_TIRES_DESCR;
            products.push(newTires6ProductData)
        }

        return products.concat(additionalItems);
    }

    return <Grid container spacing={1}>

        <Grid item xs={7.2}>
            <Grid container spacing={1} style={{margin: 10}}>

                {editOrder ? <Grid item sm={9}>
                    <Typography align={'right'}>Редактирате {editOrder.car ? editOrder.car.licence_plate : null}</Typography>
                </Grid> : null}
                {editOrder ? <Grid item sm={2}>
                    <Button onClick={() => setEditOrder(null)}>Отмени редакция</Button>
                </Grid> : null}

                <Grid item sm={2} md={2}>
                    <ToggleButtonGroup
                        color="primary"
                        orientation="vertical"
                        value={isJeepBus}
                        exclusive
                        style={toggleRaisedError && !isJeepBus ? {outlineColor: 'red', outlineStyle: 'solid', outlineWidth: '1px'} : {}}
                        onChange={(event, checked) => setJeepBus(checked)}
                        aria-label="Platform"
                    >
                        <ToggleButton value="auto">Лек автомобил</ToggleButton>
                        <ToggleButton value="jeep">Джип / бус</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item sm={10.5} md={8}>
                    <TiresSelectionSimplifiedComponent
                        selectedTiresType={selectedTiresType}
                        setSelectedTiresType={setSelectedTiresType}
                        frontValue={frontValue}
                        rearValue={rearValue}
                        setFrontValue={setFrontValue}
                        setRearValue={setRearValue}/>
                </Grid>

                <Grid item sm={5.5}>
                    <NewTiresSelectionComponent
                        selectedTiresType={newSelectedTiresType}
                        setSelectedTiresType={setNewSelectedTiresType}
                        numTires={numTires}                                 setNumTires={setNumTires}
                        newTires1={newTires1}                               setNewTires1={setNewTires1}
                        newTires2={newTires2}                               setNewTires2={setNewTires2}
                        newTires3={newTires3}                               setNewTires3={setNewTires3}
                        newTires4={newTires4}                               setNewTires4={setNewTires4}
                        newTires5={newTires5}                               setNewTires5={setNewTires5}
                        newTires6={newTires6}                               setNewTires6={setNewTires6}



                        newTires1ProductData={newTires1ProductData}         setNewTires1ProductData={setNewTires1ProductData}
                        newTires2ProductData={newTires2ProductData}         setNewTires2ProductData={setNewTires2ProductData}
                        newTires3ProductData={newTires3ProductData}         setNewTires3ProductData={setNewTires3ProductData}
                        newTires4ProductData={newTires4ProductData}         setNewTires4ProductData={setNewTires4ProductData}
                        newTires5ProductData={newTires5ProductData}         setNewTires5ProductData={setNewTires5ProductData}
                        newTires6ProductData={newTires6ProductData}         setNewTires6ProductData={setNewTires6ProductData}


                        newTires1Brand={newTires1Brand}                     setNewTires1Brand={setNewTires1Brand}
                        newTires2Brand={newTires2Brand}                     setNewTires2Brand={setNewTires2Brand}
                        newTires3Brand={newTires3Brand}                     setNewTires3Brand={setNewTires3Brand}
                        newTires4Brand={newTires4Brand}                     setNewTires4Brand={setNewTires4Brand}
                        newTires5Brand={newTires5Brand}                     setNewTires5Brand={setNewTires5Brand}
                        newTires6Brand={newTires6Brand}                     setNewTires6Brand={setNewTires6Brand}



                        newTires1Num={newTires1Num}                         setNewTires1Num={setNewTires1Num}
                        newTires2Num={newTires2Num}                         setNewTires2Num={setNewTires2Num}
                        newTires3Num={newTires3Num}                         setNewTires3Num={setNewTires3Num}
                        newTires4Num={newTires4Num}                         setNewTires4Num={setNewTires4Num}
                        newTires5Num={newTires5Num}                         setNewTires5Num={setNewTires5Num}
                        newTires6Num={newTires6Num}                         setNewTires6Num={setNewTires6Num}



                        newTires1Type={newTires1Type}                       setNewTires1Type={setNewTires1Type}
                        newTires2Type={newTires2Type}                       setNewTires2Type={setNewTires2Type}
                        newTires3Type={newTires3Type}                       setNewTires3Type={setNewTires3Type}
                        newTires4Type={newTires4Type}                       setNewTires4Type={setNewTires4Type}
                        newTires5Type={newTires5Type}                       setNewTires5Type={setNewTires5Type}
                        newTires6Type={newTires6Type}                       setNewTires6Type={setNewTires6Type}



                        newTireWeightIndex1={newTireWeightIndex1}           setNewTireWeightIndex1={setNewTireWeightIndex1}
                        newTireWeightIndex2={newTireWeightIndex2}           setNewTireWeightIndex2={setNewTireWeightIndex2}
                        newTireWeightIndex3={newTireWeightIndex3}           setNewTireWeightIndex3={setNewTireWeightIndex3}
                        newTireWeightIndex4={newTireWeightIndex4}           setNewTireWeightIndex4={setNewTireWeightIndex4}
                        newTireWeightIndex5={newTireWeightIndex5}           setNewTireWeightIndex5={setNewTireWeightIndex5}
                        newTireWeightIndex6={newTireWeightIndex6}           setNewTireWeightIndex6={setNewTireWeightIndex6}



                        newTireSpeedIndex1={newTireSpeedIndex1}             setNewTireSpeedIndex1={setNewTireSpeedIndex1}
                        newTireSpeedIndex2={newTireSpeedIndex2}             setNewTireSpeedIndex2={setNewTireSpeedIndex2}
                        newTireSpeedIndex3={newTireSpeedIndex3}             setNewTireSpeedIndex3={setNewTireSpeedIndex3}
                        newTireSpeedIndex4={newTireSpeedIndex4}             setNewTireSpeedIndex4={setNewTireSpeedIndex4}
                        newTireSpeedIndex5={newTireSpeedIndex5}             setNewTireSpeedIndex5={setNewTireSpeedIndex5}
                        newTireSpeedIndex6={newTireSpeedIndex6}             setNewTireSpeedIndex6={setNewTireSpeedIndex6}

                    />
                </Grid>


                <Grid item xs={4.5}>
                    <PropsWithOptionsComponent
                        shouldSum
                        name={'Хотел'}
                        options={[hotelDjanta, hotelNoDjanta]}
                        optionNames={['С джанта', 'Без джанта']}
                        setOptions={[setHotelDjanta, setHotelNoDjanta]}
                        setOptionsDialogOpen={setHotelOpen}
                        jsxDialog={<HotelPickComponent open={hotelOpen} handleClose={() => setHotelOpen(false)}
                                                optionNames={['С джанта', 'Без джанта']}
                                                onClick={[     null,    null,   null,       null,   null,        null]}

                                                numHotels={numHotels} setNumHotels={setNumHotels}

                                                options={[hotelDjanta, hotelNoDjanta]}
                                                tiresBrand={hotelTiresBrand} setTiresBrand={setHotelTiresBrand}
                                                tiresSize={hotelTiresSize} setTiresSize={setHotelTiresSize}
                                                speedIndex={hotelSpeedIndex} setSpeedIndex={setHotelSpeedIndex}
                                                weightIndex={hotelWeightIndex} setWeightIndex={setHotelWeightIndex}
                                                dot={dot} setDot={setDot}
                                                hotelNote={hotelNote} setHotelNote={setHotelNote}
                                                setOptions={[setHotelDjanta, setHotelNoDjanta]}



                                                options2={[hotelDjanta2, hotelNoDjanta2]}
                                                tiresBrand2={hotelTiresBrand2} setTiresBrand2={setHotelTiresBrand2}
                                                tiresSize2={hotelTiresSize2} setTiresSize2={setHotelTiresSize2}
                                                speedIndex2={hotelSpeedIndex2} setSpeedIndex2={setHotelSpeedIndex2}
                                                weightIndex2={hotelWeightIndex2} setWeightIndex2={setHotelWeightIndex2}
                                                dot2={dot2} setDot2={setDot2}
                                                hotelNote2={hotelNote2} setHotelNote2={setHotelNote2}
                                                setOptions2={[setHotelDjanta2, setHotelNoDjanta2]}



                                                options3={[hotelDjanta3, hotelNoDjanta3]}
                                                tiresBrand3={hotelTiresBrand3} setTiresBrand3={setHotelTiresBrand3}
                                                tiresSize3={hotelTiresSize3} setTiresSize3={setHotelTiresSize3}
                                                speedIndex3={hotelSpeedIndex3} setSpeedIndex3={setHotelSpeedIndex3}
                                                weightIndex3={hotelWeightIndex3} setWeightIndex3={setHotelWeightIndex3}
                                                dot3={dot3} setDot3={setDot3}
                                                hotelNote3={hotelNote3} setHotelNote3={setHotelNote3}
                                                setOptions3={[setHotelDjanta3, setHotelNoDjanta3]}/>}/>

                </Grid>
                <Grid item xs={6}></Grid>


                <Grid item sm={11} md={10}>
                    <PropsComponent
                        name={'Пълна смяна'}
                        options={fullReplacementOptions}
                        setOptions={setFullReplacementOptions}/>
                </Grid>

                <Grid item sm={11} md={10}>
                    <PropsComponent
                        name={'Сваляне качване и баланс'}
                        options={downUpBalanceOptions}
                        setOptions={setDownUpBalanceOptions}/>
                </Grid>

                <Grid item sm={11} md={10}>
                    <PropsComponent
                        name={'Сваляне качване'}
                        options={downUpOptions}
                        setOptions={setDownUpOptions}/>
                </Grid>

                <Grid item sm={11} md={10}>
                    <PropsComponent
                        name={'Баланс'}
                        options={balanceOptions}
                        setOptions={setBalanceOptions}/>
                </Grid>

                <Grid item sm={11} md={10}>
                    <PropsComponent
                        name={'Монтаж'}
                        options={upOptions}
                        setOptions={setUpOptions}/>
                </Grid>

                <Grid item sm={11} md={10}>
                    <PropsComponent
                        name={'Демонтаж'}
                        options={downOptions}
                        setOptions={setDownOptions}/>
                </Grid>

                <Grid item sm={11} md={10}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <PropsWithOptionsComponent
                                shouldSum
                                name={'Лепенка'}
                                options={[stickOptions, gubaOptions, podlojkaOptions, vulkanizaciqOptions]}
                                optionNames={['Обикновена', 'Гъба', 'Подложка', 'Вулканизация']}
                                setOptions={[setStickOptions, setGubaOptions, setPodlojkaOptions, setVulkanizaciqOptions]}
                                setOptionsDialogOpen={setStickOptionsDialog}
                                jsxDialog={<StickDialog open={stickOptionsDialog} handleClose={() => setStickOptionsDialog(false)}
                                                        optionNames={['Обикновена', 'Гъба', 'Подложка', 'Вулканизация']}
                                                        options={[stickOptions, gubaOptions, podlojkaOptions, vulkanizaciqOptions]}
                                                        setOptions={[setStickOptions, setGubaOptions, setPodlojkaOptions, setVulkanizaciqOptions]}/>}/>

                        </Grid>


                        <Grid item xs={2}>
                            <PropsWithOptionsComponent
                                shouldSum
                                name={'Азот'}
                                options={[noOptions]}
                                optionNames={['']}
                                setOptions={[setNoOptions]}
                                setOptionsDialogOpen={setNoOptionsDialog}
                                jsxDialog={<StickDialog open={noOptionsDialog} handleClose={() => setNoOptionsDialog(false)}
                                                        optionNames={['Азот']}
                                                        options={[noOptions]}
                                                        setOptions={[setNoOptions]}/>}/>

                        </Grid>


                        <Grid item xs={4}>
                            <PropsWithOptionsComponent
                                shouldSum
                                name={'Вентил'}
                                options={[ventilGumen, ventilBusov, ventilMetalen, ventilOnly, ventilTpms, ventilDatchik, ventilDatchikDemount, ventilExtension, ventilKrivka]}
                                optionNames={['Гумен', 'Бусов', 'Метален', 'Вентил за датчик само', 'Вентил за датчик + датчик (TPMS)', 'За датчик - Монтаж', 'За датчик - Демонтаж', 'Удължение', 'Кривка']}
                                setOptions={[setVentilGumen, setVentilBusov, setVentilMetalen, setVentilOnly, setVentilTpms, setVentilDatchik, setVentilDatchikDemount, setVentilExtension, setVentilKrivka]}
                                setOptionsDialogOpen={setVentilOptionsDialog}
                                jsxDialog={<StickDialog open={ventilOptionsDialog} handleClose={() => setVentilOptionsDialog(false)}
                                                        optionNames={['Гумен', 'Бусов', 'Метален', 'Вентил за датчик само', 'Вентил за датчик + датчик (TPMS)', 'За датчик - Монт.', 'За датчик - Дем.', 'Удължение', 'Кривка']}
                                                        onClick={[     null,    null,   null,       null,                    null,                               null,   null,        null]}
                                                        options={[ventilGumen, ventilBusov, ventilMetalen, ventilOnly, ventilTpms, ventilDatchik, ventilDatchikDemount, ventilExtension, ventilKrivka]}
                                                        setOptions={[setVentilGumen, setVentilBusov, setVentilMetalen, setVentilOnly, setVentilTpms, setVentilDatchik, setVentilDatchikDemount, setVentilExtension, setVentilKrivka]}/>}/>

                        </Grid>


                        <Grid item xs={2}>
                            <PropsWithOptionsComponent
                                shouldSum
                                name={'Плик'}
                                options={[plikOptions]}
                                optionNames={['']}
                                setOptions={[setPlikOptions]}
                                setOptionsDialogOpen={setPlikOptionsDialog}
                                jsxDialog={<StickDialog open={plikOptionsDialog} handleClose={() => setPlikOptionsDialog(false)}
                                                        optionNames={['Плик']}
                                                        options={[plikOptions]}
                                                        setOptions={[setPlikOptions]}/>}/>

                        </Grid>

                        <Grid item xs={4}>
                            <PropsWithOptionsComponent
                                shouldSum
                                name={'Изправяне'}
                                options={[straightOptions]}
                                optionNames={['']}
                                setOptions={[setStraightOptions]}
                                setOptionsDialogOpen={setStraightOptionsDialog}
                                jsxDialog={<StickDialog open={straightOptionsDialog} handleClose={() => setStraightOptionsDialog(false)}
                                                        optionNames={['Изправяне']}
                                                        options={[straightOptions]}
                                                        setOptions={[setStraightOptions]}/>}/>

                        </Grid>

                        <Grid item xs={2}>
                            <PropsWithOptionsComponent
                                shouldSum
                                name={'Главина'}
                                options={[glavinaOptions, mednaPastaOptions]}
                                optionNames={['Главина', 'Медна паста']}
                                setOptions={[setGlavinaOptions, setMednaPastaOptions]}
                                setOptionsDialogOpen={setGlavinaOptionsDialog}
                                jsxDialog={<StickDialog open={glavinaOptionsDialog} handleClose={() => setGlavinaOptionsDialog(false)}
                                                        optionNames={['Главина', 'Медна паста']}
                                                        options={[glavinaOptions, mednaPastaOptions]}
                                                        setOptions={[setGlavinaOptions, setMednaPastaOptions]}
                                                        customOptions={[1, 2, 4, 8]}/>}/>
                        </Grid>

                        <Grid item xs={2}>
                            <PropsWithOptionsComponent
                                shouldSum
                                name={'Отпадни'}
                                options={[otpadniOptions]}
                                optionNames={['']}
                                setOptions={[setOtpadniOptions]}
                                setOptionsDialogOpen={setOtpadniOptionsDialog}
                                jsxDialog={<StickDialog open={otpadniOptionsDialog} handleClose={() => setOtpadniOptionsDialog(false)}
                                                        optionNames={['Отпадни']}
                                                        options={[otpadniOptions]}
                                                        setOptions={[setOtpadniOptions]}/>}/>
                        </Grid>

                        <Grid item xs={2}>
                            <PropsWithOptionsComponent
                                shouldSum
                                name={'Борд'}
                                options={[bordOptions]}
                                optionNames={['']}
                                setOptions={[setBordOptions]}
                                setOptionsDialogOpen={setBordOptionsDialog}
                                jsxDialog={<StickDialog open={bordOptionsDialog} handleClose={() => setBordOptionsDialog(false)}
                                                        optionNames={['Борд']}
                                                        options={[bordOptions]}
                                                        setOptions={[setBordOptions]}/>}/>
                        </Grid>

                        <Grid item xs={2}>
                            <Paper elevation={6} style={{minHeight: CARD_HEIGHT,
                                maxHeight: BIG_CARD_HEIGHT, padding: 10, background: othersOpen ? 'green' : null}}
                                   className={'paperCenterText'}
                                   onClick={() => {
                                       setOthersOpen(!othersOpen);
                                   }}>
                                Други
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>

                <StickDialog open={datchikDialog} handleClose={() => setDatchikDialog(false)}
                             optionNames={['Датчик']}
                             customOptions={['Монтаж', 'Демонтаж']}
                             options={[datchikOptions]}
                             setOptions={[setDatchikOptions]}/>

            </Grid>

        </Grid>


        <Grid item xs={4.8} style={{padding: 10, height: '100vh', overflowY: 'auto'}}>

            <Grid container spacing={1}>
                <Grid item xs={9.8}>
                    {!editOrder ? <Button style={{marginTop: 20, height: CARD_HEIGHT}} fullWidth variant={'contained'} onClick={() => {
                        if (!isJeepBus) {
                            setMessageText('Моля, изберете дали работите с лек автомобил или с джип/бус')
                            setToggleRaisedError(true)
                            return
                        }
                        if (!frontValue) {
                            setMessageText('Моля, изберете цолаж')
                            return;
                        }
                        let products = computeProducts()
                        setConfirmOpen(products)
                        setWithExistingId(null)
                    }}>Създай поръчка</Button> : null}

                    {editOrder ? <Button style={{marginTop: 20, height: CARD_HEIGHT}} fullWidth variant={'contained'} onClick={() => {
                        if (!isJeepBus) {
                            setMessageText('Моля, изберете дали работите с лек автомобил или с джип/бус')
                            setToggleRaisedError(true)
                            return
                        }
                        if (!frontValue) {
                            setMessageText('Моля, изберете цолаж')
                            return;
                        }
                        let products = computeProducts()
                        if (editOrder) {
                            setConfirmOpen(products)
                            setWithExistingId(editOrder.id)
                            return;
                        }

                        setConfirmExistingOrderOpen(products)
                    }}>{editOrder ? `Редакция на поръчка #${editOrder.id} за ${editOrder && editOrder.car ? editOrder.car.licence_plate : ''}` : 'Добави към съществуваща поръчка'}</Button> : null}

                </Grid>
                <Grid item xs={2.2}>
                    <Button style={{marginTop: 20, height: CARD_HEIGHT}} fullWidth variant={'outlined'} onClick={() => resetAllProps()}>
                        Изчисти
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <OrdersAroundPanel data={orderAround} fetchData={fetchData} setEditOrder={setEditOrder}
                                       givenDayMode={givenDayMode} setGivenDayMode={setGivenDayMode}
                                       setConfirmOpen={setConfirmOpen} setPresetClientData={setPresetClientData}/>
                </Grid>
            </Grid>
        </Grid>

        <AddProductsDialog open={confirmOpen} handleClose={() => setConfirmOpen(null)} products={confirmOpen} withExistingId={withExistingId} setEditOrder={setEditOrder} resetAllProps={resetAllProps}
            hotelData={
                {
                    first_description: `${maskString(hotelTiresSize??"")} ${hotelTiresBrand??""} ${hotelWeightIndex??""} ${hotelSpeedIndex??""} ${dot??""} ${hotelNote??''}`, first_quantity: hotelDjanta.reduce((a, b) => a + b, 0) + hotelNoDjanta.reduce((a, b) => a + b, 0),
                    second_description: `${maskString(hotelTiresSize2??"")} ${hotelTiresBrand2??""} ${hotelWeightIndex2??""} ${hotelSpeedIndex2??""} ${dot2??""} ${hotelNote2??''}`, second_quantity: hotelDjanta2.reduce((a, b) => a + b, 0) + hotelNoDjanta2.reduce((a, b) => a + b, 0),
                    third_description: `${maskString(hotelTiresSize3??"")} ${hotelTiresBrand3??""} ${hotelWeightIndex3??""} ${hotelSpeedIndex3??""} ${dot3??""} ${hotelNote3??''}`, third_quantity: hotelDjanta3.reduce((a, b) => a + b, 0) + hotelNoDjanta3.reduce((a, b) => a + b, 0),
                }
            } presetMechanics={presetMechanics} editOrder={editOrder} presetClientData={presetClientData} setPresetClientData={setPresetClientData}/>
        <PickOrderDialog open={confirmExistingOrderOpen} handleClose={() => setConfirmExistingOrderOpen(null)} setConfirmOpen={setConfirmOpen} products={confirmExistingOrderOpen} setWithExistingId={setWithExistingId}/>
        <AnySearchDialog open={additionalSearchOpen} handleClose={() => setAdditionalSearchOpen(null)} additionalItems={additionalItems} setAdditionalItems={setAdditionalItems}/>


        <OthersDialog
            open={othersOpen} handleClose={() => setOthersOpen(null)}


            svTailsOptions={svTailsOptions} setSvTailsOptions={setSvTailsOptions} setSvTailsOptionsDialog={setSvTailsOptionsDialog} svTailsOptionsDialog={svTailsOptionsDialog}


            boltoveDialogOpen={boltoveDialogOpen} setBoltoveDialogOpen={setBoltoveDialogOpen} boltoveCount={boltoveCount}
            setBoltoveCount={setBoltoveCount} boltoveArtNom={boltoveArtNom} setBoltoveArtNom={setBoltoveArtNom} setBoltoveAdditionalData={setBoltoveAdditionalData}


            sekretiDialogOpen={sekretiDialogOpen} setSekretiDialogOpen={setSekretiDialogOpen} sekretiCount={sekretiCount} setSekretiCount={setSekretiCount}
            sekretiArtNom={sekretiArtNom} setSekretiArtNom={setSekretiArtNom} setSekretiAdditionalData={setSekretiAdditionalData}


            setAdditionalSearchOpen={setAdditionalSearchOpen}


            presetMechanics={presetMechanics} setPresetMechanicsDialogOpen={setPresetMechanicsDialogOpen}/>

        <MessageDialog openAndText={messageText} handleClose={() => setMessageText(null)}
                       positiveText={'Добре'} positiveAction={() => setMessageText(null)}/>

        <MechanicPickDialog open={presetMechanicsDialogOpen} handleClose={() => setPresetMechanicsDialogOpen(null)}
                            mechanics={presetMechanics} setMechanics={setPresetMechanics}/>
    </Grid>
}

export default MainComponent;