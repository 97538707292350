import * as React from "react";
import MainComponent from "../components/MainComponent";
import {useState} from "react";
import {SUMMER_TIRES} from "../Constants";

function MainScreen() {

    const [selectedTiresType, setSelectedTiresType] = useState(SUMMER_TIRES);

    const [frontValue, setFrontValue] = useState(null);
    const [rearValue, setRearValue] = useState(null);

    const [fullReplacementOptions, setFullReplacementOptions] = useState([]);
    const [downUpBalanceOptions, setDownUpBalanceOptions] = useState([]);
    const [downUpOptions, setDownUpOptions] = useState([]);
    const [balanceOptions, setBalanceOptions] = useState([]);
    const [upOptions, setUpOptions] = useState([]);
    const [downOptions, setDownOptions] = useState([]);

    // Лепенка - обикновена, гъба, подложка, вулканизация
    const [stickOptions, setStickOptions] = useState([]);
    const [gubaOptions, setGubaOptions] = useState([]);
    const [podlojkaOptions, setPodlojkaOptions] = useState([]);
    const [vulkanizaciqOptions, setVulkanizaciqOptions] = useState([]);
    const [stickOptionsDialog, setStickOptionsDialog] = useState(false);

    //Азот
    const [noOptions, setNoOptions] = useState([]);
    const [noOptionsDialog, setNoOptionsDialog] = useState(false);

    //Вентил - гумен, бусов, метален, за датчик, удължение, кривка
    const [ventilGumen, setVentilGumen] = useState([]);
    const [ventilBusov, setVentilBusov] = useState([]);
    const [ventilMetalen, setVentilMetalen] = useState([]);
    const [ventilTpms, setVentilTpms] = useState([]);
    const [ventilOnly, setVentilOnly] = useState([]);
    const [ventilDatchik, setVentilDatchik] = useState([]);
    const [ventilDatchikDemount, setVentilDatchikDemount] = useState([]);
    const [ventilExtension, setVentilExtension] = useState([]);
    const [ventilKrivka, setVentilKrivka] = useState([]);
    const [ventilOptionsDialog, setVentilOptionsDialog] = useState(false);
    const [datchikDialog, setDatchikDialog] = useState(false);
    const [datchikOptions, setDatchikOptions] = useState([]);

    //Плик
    const [plikOptions, setPlikOptions] = useState([]);
    const [plikOptionsDialog, setPlikOptionsDialog] = useState(false);

    //Изправяне
    const [straightOptions, setStraightOptions] = useState([]);
    const [straightOptionsDialog, setStraightOptionsDialog] = useState(false);

    //Главина
    const [glavinaOptions, setGlavinaOptions] = useState([]);
    const [glavinaOptionsDialog, setGlavinaOptionsDialog] = useState(false);

    //Медна паста
    const [mednaPastaOptions, setMednaPastaOptions] = useState([]);
    const [mednaPastaOptionsDialog, setMednaPastaOptionsDialog] = useState(false);

    //Отпадни
    const [otpadniOptions, setOtpadniOptions] = useState([]);
    const [otpadniOptionsDialog, setOtpadniOptionsDialog] = useState(false);

    //Борд
    const [bordOptions, setBordOptions] = useState([]);
    const [bordOptionsDialog, setBordOptionsDialog] = useState(false);

    //Свински опашки
    const [svTailsOptions, setSvTailsOptions] = useState([]);
    const [svTailsOptionsDialog, setSvTailsOptionsDialog] = useState(false);



    //Болтове
    const [boltoveCount, setBoltoveCount] = useState();
    const [boltoveArtNom, setBoltoveArtNom] = useState();
    const [boltoveAdditionalData, setBoltoveAdditionalData] = useState();
    const [boltoveDialogOpen, setBoltoveDialogOpen] = useState(false);



    //Секрети
    const [sekretiCount, setSekretiCount] = useState();
    const [sekretiArtNom, setSekretiArtNom] = useState();
    const [sekretiAdditionalData, setSekretiAdditionalData] = useState();
    const [sekretiDialogOpen, setSekretiDialogOpen] = useState(false);




    // Нови гуми
    const [newSelectedTiresType, setNewSelectedTiresType] = useState(null);
    const [numTires, setNumTires] = useState(0);

    const [newTires1, setNewTires1] = useState(null);
    const [newTires2, setNewTires2] = useState(null);
    const [newTires3, setNewTires3] = useState(null);
    const [newTires4, setNewTires4] = useState(null);
    const [newTires5, setNewTires5] = useState(null);
    const [newTires6, setNewTires6] = useState(null);

    const [newTires1ProductData, setNewTires1ProductData] = useState(null);
    const [newTires2ProductData, setNewTires2ProductData] = useState(null);
    const [newTires3ProductData, setNewTires3ProductData] = useState(null);
    const [newTires4ProductData, setNewTires4ProductData] = useState(null);
    const [newTires5ProductData, setNewTires5ProductData] = useState(null);
    const [newTires6ProductData, setNewTires6ProductData] = useState(null);



    const [numHotels, setNumHotels] = useState(1);

    const [hotelOpen, setHotelOpen] = useState(null);
    const [hotelDjanta, setHotelDjanta] = useState([]);
    const [hotelNoDjanta, setHotelNoDjanta] = useState([]);
    const [hotelTiresBrand, setHotelTiresBrand] = useState([]);
    const [hotelTiresSize, setHotelTiresSize] = useState("");
    const [hotelSpeedIndex, setHotelSpeedIndex] = useState([]);
    const [hotelWeightIndex, setHotelWeightIndex] = useState([]);
    const [dot, setDot] = useState(null);
    const [hotelNote, setHotelNote] = useState(null);

    const [hotelOpen2, setHotelOpen2] = useState(null);
    const [hotelDjanta2, setHotelDjanta2] = useState([]);
    const [hotelNoDjanta2, setHotelNoDjanta2] = useState([]);
    const [hotelTiresBrand2, setHotelTiresBrand2] = useState([]);
    const [hotelTiresSize2, setHotelTiresSize2] = useState("");
    const [hotelSpeedIndex2, setHotelSpeedIndex2] = useState([]);
    const [hotelWeightIndex2, setHotelWeightIndex2] = useState([]);
    const [dot2, setDot2] = useState(null);
    const [hotelNote2, setHotelNote2] = useState(null);

    const [hotelOpen3, setHotelOpen3] = useState(null);
    const [hotelDjanta3, setHotelDjanta3] = useState([]);
    const [hotelNoDjanta3, setHotelNoDjanta3] = useState([]);
    const [hotelTiresBrand3, setHotelTiresBrand3] = useState([]);
    const [hotelTiresSize3, setHotelTiresSize3] = useState("");
    const [hotelSpeedIndex3, setHotelSpeedIndex3] = useState([]);
    const [hotelWeightIndex3, setHotelWeightIndex3] = useState([]);
    const [dot3, setDot3] = useState(null);
    const [hotelNote3, setHotelNote3] = useState(null);



    const [newTires1Brand, setNewTires1Brand] = useState([]);
    const [newTires2Brand, setNewTires2Brand] = useState([]);
    const [newTires3Brand, setNewTires3Brand] = useState([]);
    const [newTires4Brand, setNewTires4Brand] = useState([]);
    const [newTires5Brand, setNewTires5Brand] = useState([]);
    const [newTires6Brand, setNewTires6Brand] = useState([]);

    const [newTires1Num, setNewTires1Num] = useState([]);
    const [newTires2Num, setNewTires2Num] = useState([]);
    const [newTires3Num, setNewTires3Num] = useState([]);
    const [newTires4Num, setNewTires4Num] = useState([]);
    const [newTires5Num, setNewTires5Num] = useState([]);
    const [newTires6Num, setNewTires6Num] = useState([]);

    const [newTires1Type, setNewTires1Type] = useState(null);
    const [newTires2Type, setNewTires2Type] = useState(null);
    const [newTires3Type, setNewTires3Type] = useState(null);
    const [newTires4Type, setNewTires4Type] = useState(null);
    const [newTires5Type, setNewTires5Type] = useState(null);
    const [newTires6Type, setNewTires6Type] = useState(null);



    const [newTireWeightIndex1, setNewTireWeightIndex1] = useState(null);
    const [newTireWeightIndex2, setNewTireWeightIndex2] = useState(null);
    const [newTireWeightIndex3, setNewTireWeightIndex3] = useState(null);
    const [newTireWeightIndex4, setNewTireWeightIndex4] = useState(null);
    const [newTireWeightIndex5, setNewTireWeightIndex5] = useState(null);
    const [newTireWeightIndex6, setNewTireWeightIndex6] = useState(null);



    const [newTireSpeedIndex1, setNewTireSpeedIndex1] = useState(null);
    const [newTireSpeedIndex2, setNewTireSpeedIndex2] = useState(null);
    const [newTireSpeedIndex3, setNewTireSpeedIndex3] = useState(null);
    const [newTireSpeedIndex4, setNewTireSpeedIndex4] = useState(null);
    const [newTireSpeedIndex5, setNewTireSpeedIndex5] = useState(null);
    const [newTireSpeedIndex6, setNewTireSpeedIndex6] = useState(null);


    const [isJeepBus, setJeepBus] = useState(null);


    const [additionalItems, setAdditionalItems] = useState([]);

    return <MainComponent
        selectedTiresType={selectedTiresType}
        setSelectedTiresType={setSelectedTiresType}
        frontValue={frontValue}
        setFrontValue={setFrontValue}
        rearValue={rearValue}
        setRearValue={setRearValue}

        newSelectedTiresType={newSelectedTiresType} setNewSelectedTiresType={setNewSelectedTiresType}
        fullReplacementOptions={fullReplacementOptions} setFullReplacementOptions={setFullReplacementOptions}
        downUpBalanceOptions={downUpBalanceOptions} setDownUpBalanceOptions={setDownUpBalanceOptions}
        downUpOptions={downUpOptions} setDownUpOptions={setDownUpOptions}
        balanceOptions={balanceOptions} setBalanceOptions={setBalanceOptions}
        upOptions={upOptions} setUpOptions={setUpOptions}
        downOptions={downOptions} setDownOptions={setDownOptions}

        stickOptions={stickOptions}                         setStickOptions={setStickOptions}
        gubaOptions={gubaOptions}                           setGubaOptions={setGubaOptions}
        podlojkaOptions={podlojkaOptions}                   setPodlojkaOptions={setPodlojkaOptions}
        vulkanizaciqOptions={vulkanizaciqOptions}           setVulkanizaciqOptions={setVulkanizaciqOptions}
        stickOptionsDialog={stickOptionsDialog}             setStickOptionsDialog={setStickOptionsDialog}



        noOptions={noOptions}                               setNoOptions={setNoOptions}
        noOptionsDialog={noOptionsDialog}                   setNoOptionsDialog={setNoOptionsDialog}



        ventilGumen={ventilGumen}                           setVentilGumen={setVentilGumen}
        ventilBusov={ventilBusov}                           setVentilBusov={setVentilBusov}
        ventilMetalen={ventilMetalen}                       setVentilMetalen={setVentilMetalen}
        ventilOnly={ventilOnly}                             setVentilOnly={setVentilOnly}
        ventilTpms={ventilTpms}                             setVentilTpms={setVentilTpms}
        ventilDatchik={ventilDatchik}                       setVentilDatchik={setVentilDatchik}
        ventilDatchikDemount={ventilDatchikDemount}         setVentilDatchikDemount={setVentilDatchikDemount}
        ventilExtension={ventilExtension}                   setVentilExtension={setVentilExtension}
        ventilKrivka={ventilKrivka}                         setVentilKrivka={setVentilKrivka}
        ventilOptionsDialog={ventilOptionsDialog}           setVentilOptionsDialog={setVentilOptionsDialog}
        datchikDialog={datchikDialog}                       setDatchikDialog={setDatchikDialog}
        datchikOptions={datchikOptions}                     setDatchikOptions={setDatchikOptions}



        plikOptions={plikOptions}                           setPlikOptions={setPlikOptions}
        plikOptionsDialog={plikOptionsDialog}               setPlikOptionsDialog={setPlikOptionsDialog}



        straightOptions={straightOptions}                   setStraightOptions={setStraightOptions}
        straightOptionsDialog={straightOptionsDialog}       setStraightOptionsDialog={setStraightOptionsDialog}



        glavinaOptions={glavinaOptions}                     setGlavinaOptions={setGlavinaOptions}
        glavinaOptionsDialog={glavinaOptionsDialog}         setGlavinaOptionsDialog={setGlavinaOptionsDialog}



        mednaPastaOptions={mednaPastaOptions}               setMednaPastaOptions={setMednaPastaOptions}
        mednaPastaOptionsDialog={mednaPastaOptionsDialog}   setMednaPastaOptionsDialog={setMednaPastaOptionsDialog}



        otpadniOptions={otpadniOptions}                     setOtpadniOptions={setOtpadniOptions}
        otpadniOptionsDialog={otpadniOptionsDialog}         setOtpadniOptionsDialog={setOtpadniOptionsDialog}



        bordOptions={bordOptions}                           setBordOptions={setBordOptions}
        bordOptionsDialog={bordOptionsDialog}               setBordOptionsDialog={setBordOptionsDialog}



        svTailsOptions={svTailsOptions}                     setSvTailsOptions={setSvTailsOptions}
        svTailsOptionsDialog={svTailsOptionsDialog}         setSvTailsOptionsDialog={setSvTailsOptionsDialog}



        boltoveDialogOpen={boltoveDialogOpen}               setBoltoveDialogOpen={setBoltoveDialogOpen}
        boltoveCount={boltoveCount}                         setBoltoveCount={setBoltoveCount}
        boltoveArtNom={boltoveArtNom}                       setBoltoveArtNom={setBoltoveArtNom}
        boltoveAdditionalData={boltoveAdditionalData}       setBoltoveAdditionalData={setBoltoveAdditionalData}


        sekretiDialogOpen={sekretiDialogOpen}               setSekretiDialogOpen={setSekretiDialogOpen}
        sekretiCount={sekretiCount}                         setSekretiCount={setSekretiCount}
        sekretiArtNom={sekretiArtNom}                       setSekretiArtNom={setSekretiArtNom}
        sekretiAdditionalData={sekretiAdditionalData}       setSekretiAdditionalData={setSekretiAdditionalData}


        numTires={numTires}                                 setNumTires={setNumTires}
        newTires1={newTires1}                               setNewTires1={setNewTires1}
        newTires2={newTires2}                               setNewTires2={setNewTires2}
        newTires3={newTires3}                               setNewTires3={setNewTires3}
        newTires4={newTires4}                               setNewTires4={setNewTires4}
        newTires5={newTires5}                               setNewTires5={setNewTires5}
        newTires6={newTires6}                               setNewTires6={setNewTires6}
        newTires1ProductData={newTires1ProductData}         setNewTires1ProductData={setNewTires1ProductData}
        newTires2ProductData={newTires2ProductData}         setNewTires2ProductData={setNewTires2ProductData}
        newTires3ProductData={newTires3ProductData}         setNewTires3ProductData={setNewTires3ProductData}
        newTires4ProductData={newTires4ProductData}         setNewTires4ProductData={setNewTires4ProductData}
        newTires5ProductData={newTires5ProductData}         setNewTires5ProductData={setNewTires5ProductData}
        newTires6ProductData={newTires6ProductData}         setNewTires6ProductData={setNewTires6ProductData}



        numHotels={numHotels}                               setNumHotels={setNumHotels}

        hotelOpen={hotelOpen}                               setHotelOpen={setHotelOpen}
        hotelDjanta={hotelDjanta}                           setHotelDjanta={setHotelDjanta}
        hotelNoDjanta={hotelNoDjanta}                       setHotelNoDjanta={setHotelNoDjanta}
        hotelTiresBrand={hotelTiresBrand}                   setHotelTiresBrand={setHotelTiresBrand}
        hotelTiresSize={hotelTiresSize}                     setHotelTiresSize={setHotelTiresSize}
        hotelSpeedIndex={hotelSpeedIndex}                   setHotelSpeedIndex={setHotelSpeedIndex}
        hotelWeightIndex={hotelWeightIndex}                 setHotelWeightIndex={setHotelWeightIndex}
        dot={dot}                                           setDot={setDot}
        hotelNote={hotelNote}                               setHotelNote={setHotelNote}

        hotelDjanta2={hotelDjanta2}                         setHotelDjanta2={setHotelDjanta2}
        hotelNoDjanta2={hotelNoDjanta2}                     setHotelNoDjanta2={setHotelNoDjanta2}
        hotelTiresBrand2={hotelTiresBrand2}                 setHotelTiresBrand2={setHotelTiresBrand2}
        hotelTiresSize2={hotelTiresSize2}                   setHotelTiresSize2={setHotelTiresSize2}
        hotelSpeedIndex2={hotelSpeedIndex2}                 setHotelSpeedIndex2={setHotelSpeedIndex2}
        hotelWeightIndex2={hotelWeightIndex2}               setHotelWeightIndex2={setHotelWeightIndex2}
        dot2={dot2}                                         setDot2={setDot2}
        hotelNote2={hotelNote2}                             setHotelNote2={setHotelNote2}

        hotelDjanta3={hotelDjanta3}                         setHotelDjanta3={setHotelDjanta3}
        hotelNoDjanta3={hotelNoDjanta3}                     setHotelNoDjanta3={setHotelNoDjanta3}
        hotelTiresBrand3={hotelTiresBrand3}                 setHotelTiresBrand3={setHotelTiresBrand3}
        hotelTiresSize3={hotelTiresSize3}                   setHotelTiresSize3={setHotelTiresSize3}
        hotelSpeedIndex3={hotelSpeedIndex3}                 setHotelSpeedIndex3={setHotelSpeedIndex3}
        hotelWeightIndex3={hotelWeightIndex3}               setHotelWeightIndex3={setHotelWeightIndex3}
        dot3={dot3}                                         setDot3={setDot3}
        hotelNote3={hotelNote3}                             setHotelNote3={setHotelNote3}

        newTires1Brand={newTires1Brand}                     setNewTires1Brand={setNewTires1Brand}
        newTires2Brand={newTires2Brand}                     setNewTires2Brand={setNewTires2Brand}
        newTires3Brand={newTires3Brand}                     setNewTires3Brand={setNewTires3Brand}
        newTires4Brand={newTires4Brand}                     setNewTires4Brand={setNewTires4Brand}
        newTires5Brand={newTires5Brand}                     setNewTires5Brand={setNewTires5Brand}
        newTires6Brand={newTires6Brand}                     setNewTires6Brand={setNewTires6Brand}



        newTires1Num={newTires1Num}                         setNewTires1Num={setNewTires1Num}
        newTires2Num={newTires2Num}                         setNewTires2Num={setNewTires2Num}
        newTires3Num={newTires3Num}                         setNewTires3Num={setNewTires3Num}
        newTires4Num={newTires4Num}                         setNewTires4Num={setNewTires4Num}
        newTires5Num={newTires5Num}                         setNewTires5Num={setNewTires5Num}
        newTires6Num={newTires6Num}                         setNewTires6Num={setNewTires6Num}



        newTires1Type={newTires1Type}                       setNewTires1Type={setNewTires1Type}
        newTires2Type={newTires2Type}                       setNewTires2Type={setNewTires2Type}
        newTires3Type={newTires3Type}                       setNewTires3Type={setNewTires3Type}
        newTires4Type={newTires4Type}                       setNewTires4Type={setNewTires4Type}
        newTires5Type={newTires5Type}                       setNewTires5Type={setNewTires5Type}
        newTires6Type={newTires6Type}                       setNewTires6Type={setNewTires6Type}



        newTireWeightIndex1={newTireWeightIndex1}           setNewTireWeightIndex1={setNewTireWeightIndex1}
        newTireWeightIndex2={newTireWeightIndex2}           setNewTireWeightIndex2={setNewTireWeightIndex2}
        newTireWeightIndex3={newTireWeightIndex3}           setNewTireWeightIndex3={setNewTireWeightIndex3}
        newTireWeightIndex4={newTireWeightIndex4}           setNewTireWeightIndex4={setNewTireWeightIndex4}
        newTireWeightIndex5={newTireWeightIndex5}           setNewTireWeightIndex5={setNewTireWeightIndex5}
        newTireWeightIndex6={newTireWeightIndex6}           setNewTireWeightIndex6={setNewTireWeightIndex6}



        newTireSpeedIndex1={newTireSpeedIndex1}             setNewTireSpeedIndex1={setNewTireSpeedIndex1}
        newTireSpeedIndex2={newTireSpeedIndex2}             setNewTireSpeedIndex2={setNewTireSpeedIndex2}
        newTireSpeedIndex3={newTireSpeedIndex3}             setNewTireSpeedIndex3={setNewTireSpeedIndex3}
        newTireSpeedIndex4={newTireSpeedIndex4}             setNewTireSpeedIndex4={setNewTireSpeedIndex4}
        newTireSpeedIndex5={newTireSpeedIndex5}             setNewTireSpeedIndex5={setNewTireSpeedIndex5}
        newTireSpeedIndex6={newTireSpeedIndex6}             setNewTireSpeedIndex6={setNewTireSpeedIndex6}

        isJeepBus={isJeepBus}                               setJeepBus={setJeepBus}



        additionalItems={additionalItems}                   setAdditionalItems={setAdditionalItems}
    />
}

export default MainScreen;