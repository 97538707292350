import {ButtonBase, Grid, Paper, Typography} from "@mui/material";
import {CARD_HEIGHT} from "../Constants";

function OptionsPicker({options, setOptions, customOptions}) {
    return (
        <Grid container spacing={2}>
            {(customOptions || [1, 2, 4]).map((element) => (
            <Grid item xs={customOptions && customOptions.length > 0 ? 12 / customOptions.length : 4}
                  onClick={() => {
                      if (options) {
                          let newOptions = options;
                          if (newOptions.includes(element)) {
                              newOptions = newOptions.filter(function(item) {
                                  return item !== element
                              })
                          } else {
                              newOptions.push(element)
                          }
                          console.log('setting', newOptions)
                          setOptions([...newOptions])
                      }
                  }}>
                <ButtonBase style={{width: '100%'}}>
                    <Paper elevation={6}
                        style={{width: '100%', height: CARD_HEIGHT, background: options && options.includes(element) ? 'green' : 'white', cursor: 'pointer'}}
                        className={'paperCenterText'}>
                        <Typography variant={'h6'}>{element}</Typography>
                    </Paper>
                </ButtonBase>
            </Grid> ))
            }
        </Grid>
    )
}
export default OptionsPicker;