import {AppBar, ButtonBase, Dialog, Grid, IconButton, Paper, Toolbar} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import PropsWithOptionsComponent from "../components/controllers/props/PropsWithOptionsComponent";
import StickDialog from "./StickDialog";
import CountNumComponent from "../components/controllers/props/CountNumComponent";
import {BIG_CARD_HEIGHT} from "../Constants";

function OthersDialog({open, handleClose,


                          svTailsOptions, setSvTailsOptions, setSvTailsOptionsDialog, svTailsOptionsDialog,


                          boltoveDialogOpen, setBoltoveDialogOpen, boltoveCount,
                          setBoltoveCount, boltoveArtNom, setBoltoveArtNom, setBoltoveAdditionalData,


                          sekretiDialogOpen, setSekretiDialogOpen, sekretiCount, setSekretiCount,
                          sekretiArtNom, setSekretiArtNom, setSekretiAdditionalData,


                          setAdditionalSearchOpen,


                          setPresetMechanicsDialogOpen
                      }) {
    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'md'} fullWidth>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            
            <Grid container spacing={1} style={{margin: 10}}>
                <Grid item xs={3}>
                    <PropsWithOptionsComponent
                        shouldSum
                        name={'Свински опашки'}
                        options={[svTailsOptions]}
                        optionNames={['']}
                        setOptions={[setSvTailsOptions]}
                        setOptionsDialogOpen={setSvTailsOptionsDialog}
                        jsxDialog={<StickDialog open={svTailsOptionsDialog}
                                                handleClose={() => setSvTailsOptionsDialog(false)}
                                                optionNames={['Свински опашки']}
                                                customOptions={[1, 2, 4, 6]}
                                                options={[svTailsOptions]}
                                                setOptions={[setSvTailsOptions]}/>}/>
                </Grid>

                <Grid item xs={2}>
                    <CountNumComponent
                        name={'Болтове'}
                        open={boltoveDialogOpen}
                        setOpen={setBoltoveDialogOpen}
                        handleClose={() => setBoltoveDialogOpen(false)}
                        count={boltoveCount}
                        setCount={setBoltoveCount}
                        artNom={boltoveArtNom}
                        setArtNom={setBoltoveArtNom}
                        setProductData={setBoltoveAdditionalData}/>
                </Grid>

                <Grid item xs={2}>
                    <CountNumComponent
                        name={'Секрети'}
                        open={sekretiDialogOpen}
                        setOpen={setSekretiDialogOpen}
                        handleClose={() => setSekretiDialogOpen(false)}
                        count={sekretiCount}
                        setCount={setSekretiCount}
                        artNom={sekretiArtNom}
                        setArtNom={setSekretiArtNom}
                        setProductData={setSekretiAdditionalData}/>
                </Grid>

                <Grid item xs={2}>
                    <ButtonBase>
                        <Paper
                            elevation={6}
                            className={'paperCenterText'}
                            style={{width: '100%', cursor: 'pointer', height: BIG_CARD_HEIGHT, padding: 10}}
                            onClick={() => {
                                setAdditionalSearchOpen(true)
                            }}>
                            Допълнителни продукти
                        </Paper>
                    </ButtonBase>

                </Grid>
                <Grid item xs={1.5}>
                    <ButtonBase>
                        <Paper
                            elevation={6}
                            className={'paperCenterText'}
                            style={{width: '100%', cursor: 'pointer', height: BIG_CARD_HEIGHT, padding: 10}}
                            onClick={() => {
                                setPresetMechanicsDialogOpen(true)
                            }}>
                            Механик на клетка
                        </Paper>
                    </ButtonBase>

                </Grid>
            </Grid>
            
        </Dialog>
    )
}
export default OthersDialog;