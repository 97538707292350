import {
    AppBar, Box, Button, Chip,
    Dialog,
    FormControl,
    Grid,
    IconButton,
    InputLabel, MenuItem,
    OutlinedInput,
    Select,
    Toolbar,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import {useEffect} from "react";
import {CARD_HEIGHT} from "../Constants";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function MechanicPickDialog({open, handleClose, mechanics, setMechanics}) {
    const [names, setNames] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setMechanics(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/api/tires-soft/mechanics?`+new URLSearchParams({
            warehouseId: 1
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then(result => result.json())
            .then((result) => {
                setNames(result)
            })
    }, [open])



    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'md'} fullWidth>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Изберете механици</Typography>

                </Toolbar>
            </AppBar>

            <Grid container spacing={1} style={{padding: 10}}>
                <Grid item xs={12}>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-chip-label">Механици</InputLabel>
                        <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={mechanics}
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Механици" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={(names?.find(e => e.id === value) ?? {}).name} />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >

                            {names && names.map((item) => (
                                <MenuItem
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <Button style={{height: CARD_HEIGHT}} variant={'contained'} onClick={() => {
                        handleClose()
                    }}>Запис</Button>
                </Grid>
            </Grid>
        </Dialog>
    )
}