import * as React from "react";
import {Grid, Paper, Typography} from "@mui/material";
import TiresTypeAndSizeComponent from "./TiresTypeSndSizeComponent";
import {ALL_SEASONS_TIRES, CARD_HEIGHT, SUMMER_TIRES, WINTER_TIRES} from "../../../Constants";

function TiresSelectionComponent({selectedTiresType, setSelectedTiresType,
                                     frontValue, rearValue, setFrontValue, setRearValue}) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Paper elevation={6} style={{height: CARD_HEIGHT}} className={'paperCenterText'}>
                    <Typography>Размер гуми</Typography>
                </Paper>
            </Grid>
            {
                [SUMMER_TIRES, WINTER_TIRES, ALL_SEASONS_TIRES].map((type) => {
                    return (
                    <Grid item xs={2}>
                        <TiresTypeAndSizeComponent selected={selectedTiresType === type}
                                                   setSelectedTiresType={setSelectedTiresType}
                                                   frontValue={frontValue} rearValue={rearValue}
                                                   setFrontValue={setFrontValue} setRearValue={setRearValue}
                                                   type={type}/>
                    </Grid>
                )})

            }
        </Grid>
    )
}
export default TiresSelectionComponent;