import * as React from "react";
import {
    AppBar,
    Button,
    ButtonBase,
    Dialog,
    Grid,
    IconButton,
    Paper, Table, TableCell, TableRow,
    TextField,
    Toolbar, Tooltip,
    Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {useEffect, useState} from "react";
import Keyboard09 from "../customViews/Keyboard09";
import DeleteIcon from "@mui/icons-material/Delete";
import MessageDialog from "../components/helper/MessageDialog";

function EditOrderValuePickDialog({open, order, predefinedValue, handleClose, allowedLetters, setEditOrder, onLanguageToggle}) {
    const [showKeyboard1, setShowKeyboard1] = useState(false);

    const [value, setValue] = useState(null);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [orderApi, setOrderApi] = useState();

    const [forceRefresh, setForceRefresh] = useState(false);

    useEffect(() => {
        if (order) {
            fetch(`${process.env.REACT_APP_URL}/api/products/order/get?id=${order.id}`, {
                method: "GET",
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(result => result.json())
                .then((result) => {
                    setOrderApi(result)

                })
                .catch(() => {

                })
        }
    }, [open, order, forceRefresh])

    useEffect(() => {
        if (predefinedValue) {
            setValue(predefinedValue)
        }
    }, [predefinedValue])

    return (
    <Dialog open={open} onClose={handleClose} maxWidth={'md'} fullWidth>
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>

                {order && order.clients ?
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    <b>{order && order.clients ? order.clients.name : null} - {order && order.clients ? order.clients.telephone : null}</b>
                </Typography>
                    : null}
            </Toolbar>
        </AppBar>

        <Grid container spacing={1} style={{padding: 10}}>
            <Grid item xs={6.5}>
                <Grid container spacing={1}>
                    <Tooltip title={value ? value : 'Липсва'}>
                        <Grid item xs={12}>
                            <TextField value={value} fullWidth
                                       onChange={showKeyboard1 ? () => {} : (e) => setValue(e.target.value)}/>
                        </Grid>
                    </Tooltip>

                    <Grid item xs={12}>
                        {(order ? order.order_status : null) < 4 ?
                        <Button onClick={(e) => {
                            e.stopPropagation()
                            e.nativeEvent.stopImmediatePropagation();

                            setEditOrder(order)
                            handleClose()
                        }}>
                            Редакция
                        </Button> : null}
                    </Grid>

                    <Grid item xs={12}>
                        {orderApi && orderApi.products && orderApi.products.length>0  ? <Table sx={{border: 1}}>
                            <TableRow>
                                <TableCell sx={{border: 1}} width={'40%'}>Продукт</TableCell>
                                <TableCell sx={{border: 1}}>Кол.</TableCell>
                                <TableCell sx={{border: 1}} width={'20%'}>Ед. цена</TableCell>
                                <TableCell sx={{border: 1}}>Стойност</TableCell>
                                <TableCell sx={{border: 1}}></TableCell>
                            </TableRow>
                            {
                                orderApi.products
                                    .filter((product) => product.product)
                                    .map((product) => {
                                        return <TableRow>
                                            <TableCell sx={{border: 1}} width={'40%'} align={'right'}>{product.product.name}</TableCell>
                                            <TableCell sx={{border: 1}} align={'right'}>{product.quantity}</TableCell>
                                            <TableCell sx={{border: 1}} width={'20%'} align={'right'}>{product.price ? product.price.toFixed(2) : "0.00"}</TableCell>
                                            <TableCell sx={{border: 1}} align={'right'}>{product.total ? product.total.toFixed(2) : "0.00"}</TableCell>
                                            <TableCell sx={{border: 1}} align={'right'}>
                                                <IconButton onClick={() => {
                                                    setDeleteConfirmationOpen({
                                                        id: product.id,
                                                        text: `Наистина ли искате да изтриете ${product.product.name} от поръчката?`
                                                    })
                                                }}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    })
                            }

                        </Table>
                        :
                        <Typography>Няма елементи в поръчката</Typography>}
                    </Grid>
                </Grid>

            </Grid>

            <Grid item xs={5.5}>
                <Keyboard09 customOptions={allowedLetters} input={value} setInput={setValue} onSave={() => {
                    handleClose(value)
                }} onLanguageToggle={onLanguageToggle}
                    showKeyboard={showKeyboard1} setShowKeyboard={setShowKeyboard1}/>
            </Grid>
        </Grid>

        <MessageDialog
            openAndText={deleteConfirmationOpen ? deleteConfirmationOpen.text : null}
            handleClose={() => setDeleteConfirmationOpen(null)}
            negativeText={'Не'}
            negativeAction={() => {
                setDeleteConfirmationOpen(null)
            }}
            positiveText={'Да'}
            positiveAction={() => {
                fetch(`${process.env.REACT_APP_URL}/api/products/order/delete`, {
                    method: "POST",
                    mode: 'cors',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        id: deleteConfirmationOpen.id
                    })
                })
                    .then(result => result.json())
                    .then((result) => {
                        setForceRefresh(Math.random())
                        setDeleteConfirmationOpen(null)
                    })
                    .catch(() => {

                    })
            }}/>

    </Dialog>
    )
}
export default EditOrderValuePickDialog;