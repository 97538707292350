import {useEffect, useState} from "react";
import {ButtonBase, Divider, Grid, List, ListItem, Typography} from "@mui/material";
import * as React from "react";
import MessageDialog from "../../helper/MessageDialog";

function ProductSearchComponent({handleClose, searchTerm, setProductData, quantity, searchTermToDisplay, setEnableCreation}) {

    const [message, setMessage] = useState();

    const [productsSuggestions, setProductsSuggestions] = useState();

    useEffect(() => {
        if (searchTerm && searchTerm.length > 2) {
            console.log('searchTerm ', searchTerm.replace('/', ' '))

            fetch(`${process.env.REACT_APP_URL}/api/products/search?`+new URLSearchParams({
                input: searchTerm.replace('/', ' '),
                warehouseId: 1
            }), {
                method: "GET",
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((result) => result.json())
                .then((result) => {
                    let items = result;
                    if (items) {
                        items.length = Math.min(10, items.length);
                        setProductsSuggestions([...items])
                    }
                })
        } else {
            setProductsSuggestions([])
        }
    }, [searchTerm])

    return (
        <Grid container>
            <Grid item xs={10}>
                <List>
                    {
                        (productsSuggestions && productsSuggestions.length > 0) ? productsSuggestions
                            .map((product) => {
                                return <ListItem style={{minHeight: 65}}>
                                    <ButtonBase fullWidth style={{width: '100%', minHeight: 60}}
                                                onClick={() => {
                                                    if (Number(quantity)<=0) {
                                                        setMessage('Изберете брой гуми първо!')
                                                        return;
                                                    }
                                                    let item = {
                                                        id: product.product_id,
                                                        name: product ? product.name + ' ' + (product.code??'') : 'n/a',
                                                        quantity: Number(quantity.reduce((partialSum, a) => partialSum + a, 0))
                                                    }
                                                    setProductData(item)
                                                    handleClose(item);
                                                }}>
                                        {product ? product.name + ' ' + (product.code??'') : 'n/a'}
                                    </ButtonBase>
                                    <Divider/>
                                </ListItem>

                            })

                            :

                            (setEnableCreation && searchTermToDisplay) ? <ListItem style={{minHeight: 65}}>
                                <ButtonBase fullWidth style={{width: '100%', minHeight: 60}}
                                            onClick={() => {
                                                setEnableCreation(searchTermToDisplay)
                                            }}>
                                    <Typography><b>Добави</b> {searchTermToDisplay}</Typography>
                                </ButtonBase>
                            </ListItem>
                                : <Typography>Няма резултати</Typography>

                    }
                </List>

            </Grid>

            <MessageDialog openAndText={message} handleClose={() => setMessage(null)}
                           positiveText={'Добре'} positiveAction={() => setMessage(null)}/>

        </Grid>

    )
}
export default ProductSearchComponent;