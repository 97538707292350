import {
    AppBar,
    Button,
    Dialog,
    Grid,
    IconButton,
    List,
    ListItem,
    Paper,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import ProductSearchComponent from "../components/controllers/props/ProductSearchComponent";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import Keyboard09 from "../customViews/Keyboard09";
import {useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import PropsComponent from "../components/controllers/props/PropsComponent";
import ValuePickDialog from "./ValuePickDialog";
import {ENGLISH_ALPHABET, BULGARIAN_ALPHABET, CARD_HEIGHT} from "../Constants";

function AnySearchDialog({open, handleClose, additionalItems, setAdditionalItems}) {
    const [englishOrBulgarian, setEnglishOrBulgarian] = useState(true);


    const [pricePickOpen, setPricePickOpen] = useState();
    const [descriptionPickOpen, setDescriptionPickOpen] = useState();


    const [quantity, setQuantity] = useState([]);
    const [productPrice, setProductPrice] = useState(null);
    const [productDescription, setProductDescription] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");

    const [showKeyboard1, setShowKeyboard1] = useState(false);

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'lg'} fullWidth>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Grid container spacing={1}>
                <Grid item xs={3.75}>
                    <ProductSearchComponent setProductData={() => {}}
                                            quantity={quantity}
                                            handleClose={(item) => {
                                                if (!productPrice || isNaN(productPrice) || productPrice<=0) {
                                                    setPricePickOpen(true)
                                                    return;
                                                }
                                                if (!productDescription){
                                                    setDescriptionPickOpen(true)
                                                    return;
                                                }

                                                if (productPrice) {
                                                    item.price = productPrice
                                                }
                                                if (productDescription) {
                                                    item.description = productDescription
                                                }
                                                setAdditionalItems([...additionalItems, item])
                                                setQuantity([])
                                                setSearchTerm("")
                                                setProductPrice(null)
                                                setProductDescription(null)
                    }} searchTerm={searchTerm}/>
                </Grid>
                <Grid item xs={5.6}>
                    <PropsComponent name={'Количество'} options={quantity} setOptions={setQuantity}/>

                    <br/>
                    <Grid container spacing={1} style={{marginTop: 5, marginBottom: 5}}>
                        <Grid item xs={4}>
                            <TextField value={searchTerm} label={'Търсене'}
                                       onChange={showKeyboard1 ? () => {} : (e) => setSearchTerm(e.target.value)}/>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper elevation={6} style={{width: 100, height: CARD_HEIGHT}}
                                   onClick={() => {
                                       setPricePickOpen(true)
                                   }}
                                   className={'paperCenterText'}>
                                Цена <br/>
                                {productPrice}
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper elevation={6} style={{width: 100, height: CARD_HEIGHT}}
                                   onClick={() => {
                                       setDescriptionPickOpen(true)
                                   }}
                                   className={'paperCenterText'}>
                                Описание <br/>
                                {productDescription}
                            </Paper>
                        </Grid>
                    </Grid>


                    {(pricePickOpen || descriptionPickOpen) ? null : <Keyboard09 customOptions={[
                        ...["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],
                        ...(englishOrBulgarian ? BULGARIAN_ALPHABET : ENGLISH_ALPHABET),
                        ".", "/", "-", "+", " "
                    ]} input={searchTerm} setInput={setSearchTerm} onSave={() => {
                        handleClose()
                    }} onLanguageToggle={() => {
                        setEnglishOrBulgarian(!englishOrBulgarian)
                    }} showKeyboard={showKeyboard1} setShowKeyboard={setShowKeyboard1}/>}
                </Grid>

                <Grid item xs={0.1}></Grid>
                <Grid item xs={2.5}>
                    <Typography variant={'h6'}>Продукти за добавяне</Typography>
                    <List>
                        {
                            additionalItems.map((additionalItem) => {
                                return <ListItem style={{minHeight: 65}}>
                                    {additionalItem.name} - {additionalItem.quantity} бр
                                    {additionalItem.price ? ' - ' + additionalItem.price + ' лв' : ''}
                                    {additionalItem.description ? ' - ' + additionalItem.description : ''}
                                    <IconButton onClick={() => {
                                        let newData = additionalItems.filter((item) => item.id !== additionalItem.id)
                                        setAdditionalItems(newData)
                                    }}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </ListItem>
                            })
                        }
                    </List>

                </Grid>

            </Grid>

            <ValuePickDialog open={pricePickOpen} handleClose={(value) => {
                if (value) {
                    setProductPrice(value.toString())
                }
                setPricePickOpen(null)
            }} allowedLetters={[7, 8, 9, 4, 5, 6, 1, 2, 3, 0, '.']} customLabel={'Въведи цена'}/>

            <ValuePickDialog open={descriptionPickOpen} handleClose={(value) => {
                if (value) {
                    setProductDescription(value.toString())
                }
                setDescriptionPickOpen(null)
            }} allowedLetters={[...(englishOrBulgarian ? BULGARIAN_ALPHABET : ENGLISH_ALPHABET),
                ".", "/", "-", "+", " "]}
                 onLanguageToggle={() => {
                     setEnglishOrBulgarian(!englishOrBulgarian)
                 }} customLabel={'Въведи описание'}/>
        </Dialog>
    )
}
export default AnySearchDialog;