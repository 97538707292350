import {Button, ButtonBase, Dialog, Grid, Paper, Typography} from "@mui/material";
import {BIG_CARD_HEIGHT, CARD_HEIGHT} from "../../../Constants";
import * as React from "react";
import {useEffect, useState} from "react";

export function CallToCellDialog({open, handleClose, orderId, checkOrderData, setForceRefresh}) {

    const [isCalled, setCalled] = useState(false);

    useEffect(() => {
        if (checkOrderData) {
            fetch(`${process.env.REACT_APP_URL}/api/kiosk/cars/is-in-queue?`+new URLSearchParams({
                warehouse_id: checkOrderData.warehouseId,
                vehicle_id: checkOrderData.vehicleId,
                order_id: checkOrderData.orderId,
            }), {
                method: "GET",
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(result => result.json())
                .then((result) => {
                    setCalled(result && result.length>0 ? result[0].cell_num : false)
                })
                .catch(() => {
                    setCalled(false)
                })
        } else {
            setCalled(false)
        }
    }, [checkOrderData])

    return (
        <Dialog open={open} onClose={handleClose}>
            {
                (isCalled && checkOrderData) ?
                    <Grid container spacing={1} padding={2}>
                        <Grid item xs={12}>
                            <Typography>Автомобилът вече е на клетка {isCalled}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant={'contained'} onClick={() => {
                                fetch(`${process.env.REACT_APP_URL}/api/kiosk/cars/back-to-queue`, {
                                    method: "POST",
                                    mode: 'cors',
                                    headers: {
                                        "Content-Type": "application/json"
                                    },
                                    body: JSON.stringify({
                                        warehouse_id: checkOrderData.warehouseId,
                                        vehicle_id: checkOrderData.vehicleId,
                                        order_id: checkOrderData.orderId,
                                    })
                                })
                                    .then(result => result.json())
                                    .then((result) => {
                                        setForceRefresh()
                                        handleClose()
                                    })
                            }}>Върни на опашката</Button>
                        </Grid>

                    </Grid>
                    :
                    <Grid container spacing={1} padding={2}>

                        <Grid item xs={12}>
                            <Typography>Изберете клетка</Typography>
                        </Grid>

                        {
                            [1, 2, 3, 4, 5, 6, 7, 8].map((cell) => <Grid item xs={4}>

                                    <ButtonBase style={{width: '100%'}}>
                                        <Paper style={{width: '100%', minHeight: CARD_HEIGHT, maxHeight: BIG_CARD_HEIGHT, padding: 10}}
                                               className={'paperCenterText'}
                                               onClick={() => {

                                                   fetch(`${process.env.REACT_APP_URL}/api/kiosk/cars`, {
                                                       method: "POST",
                                                       mode: 'cors',
                                                       headers: {
                                                           "Content-Type": "application/json"
                                                       },
                                                       body: JSON.stringify({
                                                           warehouse_id: 1,
                                                           order_id: orderId,
                                                           cell_num: cell,
                                                       })
                                                   })
                                                       .then(result => result.json())
                                                       .then((result) => {
                                                           setForceRefresh()
                                                           handleClose()
                                                       })
                                                   handleClose()


                                               }}>

                                            <Typography variant={'h6'}>{cell}</Typography>
                                        </Paper>
                                    </ButtonBase>
                                </Grid>

                            )
                        }

                    </Grid>

            }

        </Dialog>
    )
}