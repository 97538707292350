import {ButtonBase, Grid, Paper, Typography} from "@mui/material";
import './../App.css';
import * as React from "react";
import {useEffect, useState} from "react";
import KeyboardIcon from '@mui/icons-material/Keyboard';

function Keyboard09({input, setInput, customInputOptions, onSave, customOptions, onLanguageToggle,
                        showKeyboard, setShowKeyboard}) {

    const [rippledButton, setRippledButton] = useState(null);

    const handleClick = (number) => {
        setRippledButton(number);

        // Reset the rippledButton state after a short delay
        setTimeout(() => {
            setRippledButton(null);
        }, 200); // Adjust the delay as needed

        // Add your number button click event logic here
    };

    useEffect(() => {
        function handleKeyPress(event) {
            const keyPressed = event.key;
            const validKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ...(customOptions??[])];

            console.log('keyPressed ', keyPressed)
            if (keyPressed === "Backspace") {
                backspace();
                handleClick('backspace')
            } else if (validKeys.includes(keyPressed.toUpperCase())) {
                modifyInput(keyPressed.toUpperCase());
                handleClick(!isNaN(keyPressed) ? Number(keyPressed) : keyPressed)
            }
        }

        if (showKeyboard) {
            document.addEventListener('keydown', handleKeyPress);
        } else {
            document.removeEventListener('keydown', handleKeyPress);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [customOptions, input, showKeyboard]);

    function modifyInput(num) {
        let oldValue = input && (!isNaN(input) || customInputOptions || customOptions) ? input : '';
        console.log(oldValue)

        console.log('new value ', oldValue + '' + num)
        setInput(oldValue + '' + num)
    }
    function backspace() {
        setInput(input.slice(0, input.length - 1))
    }

    return (
        <Grid container spacing={1}>

            <Grid item xs={3}>
                <ButtonBase style={{width: '100%'}}><Paper elevation={6} className={'paperCenterText'}
                                                           style={{minHeight: 80, cursor: 'pointer', width: '100%'}}
                                                           onClick={() => {
                                                               setShowKeyboard(!showKeyboard)
                                                           }}
                >
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={12}>
                            <Typography variant={'h6'}>{showKeyboard ? 'Скрий' : 'Покажи'}</Typography>
                        </Grid>
                        <Grid item>
                            <KeyboardIcon/>
                        </Grid>
                    </Grid>

                </Paper></ButtonBase>
            </Grid>
            <Grid item xs={9}></Grid>
            {
                (showKeyboard && onLanguageToggle) ? <Grid item xs={2}>
                    <ButtonBase style={{width: '100%'}}><Paper elevation={6} className={'paperCenterText'}
                                                               style={{minHeight: 80, cursor: 'pointer', width: '100%', backgroundColor: '#7efff3'}}
                                                               onClick={() => {
                                                                   onLanguageToggle()
                                                               }}
                    ><Typography variant={'h6'}>BG/EN</Typography></Paper></ButtonBase>
                </Grid> : null
            }
            {(showKeyboard && customInputOptions) ? <Grid item xs={1}>
                <Grid container spacing={1}>
                    {customInputOptions.map((symbol) => {
                        return <Grid item xs={12}><ButtonBase style={{width: '100%'}}><Paper elevation={6} className={'paperCenterText'}
                                                  style={{minHeight: 80, cursor: 'pointer', width: '100%'}}
                                                  onClick={() => {
                                                      modifyInput(symbol)
                                                  }}
                        ><Typography variant={'h6'}>{symbol}</Typography></Paper></ButtonBase></Grid>
                    })}
                </Grid>
            </Grid> : null}

            {showKeyboard ? <Grid item xs={customInputOptions ? 11 : 12}>
                <Grid container spacing={1}>
                    {(customOptions || [7, 8, 9, 4, 5, 6, 1, 2, 3, 0]).map((num) => (
                        <Grid item xs={customOptions ? 1.5 : 4}>
                            <ButtonBase style={{width: '100%'}}>
                                <Paper elevation={6} className={'paperCenterText'}
                                       style={rippledButton === num ? {
                                           width: '100%', // Set your desired button size
                                           background: '#919191', // Set your button background color
                                           minHeight: 80,
                                           cursor: 'pointer'
                                       } : {width: '100%', minHeight: 80, cursor: 'pointer'}}
                                       onClick={() => {
                                           modifyInput(num)
                                       }}>
                                    <Typography variant={'h6'}>{num}</Typography>
                                </Paper>
                            </ButtonBase>
                        </Grid>
                    ))
                    }
                </Grid>
            </Grid> : null}


            {showKeyboard ? <Grid item xs={4}>
                <Paper elevation={6} className={'paperCenterText'}
                       style={rippledButton === 'backspace' ? {
                           background: '#919191', // Set your button background color
                           minHeight: 80,
                           cursor: 'pointer'
                       } : {minHeight: 80, cursor: 'pointer'}}

                       onClick={() => {
                           backspace()
                       }}>
                    Изтрий
                </Paper>
            </Grid> : null}

            {showKeyboard ? <Grid item xs={4}>
                <Paper elevation={6} className={'paperCenterText'} style={{minHeight: 80, cursor: 'pointer', background: 'lightblue'}}
                       onClick={() => {
                           onSave()
                       }}>
                   Запази
                </Paper>
            </Grid> : null}
        </Grid>
    )
}
export default Keyboard09;